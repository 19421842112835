import { Params } from "@angular/router";
import { TOKEN_STORAGE_KEY } from "./auth.model";

const WindowFeatures = [
  'noopener', 'noreferrer'
] as const;

export const UNSAFE_URL_PREFIX = 'unsafe:';

export type WindowFeature = string | typeof WindowFeatures[number];

export type WindowTarget = '_self' | '_blank' | '_parent' | '_top';

export interface UrlMap {
  [key: string]: string;
}

export const LANGUAGE_QUERY_PARAM = 'lang';

export const REDIRECT_URL_KEY = 'redirect_url';

export const TEST_ACCESS_PASS_QUERY_PARAM = 'test-access-pass';

export interface ProjectRedirectParams {
  url: string;
  params?: Params;
  target?: WindowTarget;
  auth?: boolean;
  testAccess?: boolean;
  keepAuth?: boolean;
  localize?: boolean;
  redirect?: boolean;
}

export interface AuthorizedParams extends Params {
  [TOKEN_STORAGE_KEY]?: string;
}