import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'countdown'
})
export class CountdownPipe implements PipeTransform {

  transform(value: string): string {
    // Парсинг входной строки в дату
    const targetDate = new Date(value);

    // Текущая дата и время
    const now = new Date();

    // Вычисление разницы во времени в миллисекундах
    let diff = targetDate.getTime() - now.getTime();

    // Если разница меньше нуля, значит дата уже прошла
    if (diff <= 0) {
      return '0д 0ч 0м 0с';
    }

    // Функция для подсчета выходных дней между двумя датами
    const countWeekendDays = (start: Date, end: Date): number => {
      let count = 0;
      const current = new Date(start);

      while (current < end) {
        const day = current.getDay();
        if (day === 0 || day === 6) {
          count++;
        }
        current.setDate(current.getDate() + 1);
      }

      return count;
    };

    // Подсчет выходных дней
    const weekendDays = countWeekendDays(now, targetDate);

    // Учитываем выходные дни в расчетах
    diff -= weekendDays * 24 * 60 * 60 * 1000;

    // Вычисление дней, часов, минут и секунд
    const days = Math.floor(diff / (1000 * 60 * 60 * 24));
    const hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((diff % (1000 * 60)) / 1000);

    // Форматирование строки результата
    return `${days}дн ${hours}ч ${minutes}м ${seconds}с`;
  }
}
