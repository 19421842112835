export const SUB_SERVICE_ID = {
  PUBLIC_DISCUSSION: 85,
  LAND_PROVISION: {
    DIRECT: {
      OLD: 102,
      CURRENT: 777
    },
    DECREE: {
      PAVLODAR: 103,
      EKIBASTUZ: 104,
      AKSU: 105
    }
  },
  SALES: {
    LAND_AUCTION: 888,
    ORG: {
      ELEC: "ELEC",
      WATR: "WATR",
      SEWG: "SEWG",
      SEWS: "SEWS",
      GAZI: "GAZI",
      HEAT: "HEAT",
      TELE: "TELE",
      RNAO: "RNAO",
      OTHR: "OTHR"
    }
  }
} as const;

export const SUB_SERVICE_ROUTE = {
  LAND_PROVISION: {
    DIRECT: '/specialist/land-provision/direct',
    DECREE: '/specialist/land-provision/decree'
  },
  SALES: {
    LAND_AUCTION: '/specialist/sales/land-auction',
    ORG: {
      WATER: '/sales-egov-comunals/water',
      WATER_SEVER: '/sales-egov-comunals/water-sever',
      HEAT: '/sales-egov-comunals/heat',
      ENERGY: '/sales-egov-comunals/energy',
      TELECOM: '/sales-egov-comunals/telecom',
      OTHR: '/sales-egov-comunals/land-relations',
      TRANS_TELECOM: '/sales-egov-comunals/trans-telecom',
      KAZAKH_TELECOM: '/sales-egov-comunals/kazakh-telecom',
      LAND_RELATIONS: '/sales-egov-comunals/land-relations'
    }
  }
} as const;

export const AULIE_ATA_SECTION_ID = 145;
export const ZU_STATISTIC_ID = 147;
