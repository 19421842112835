import { NgModule } from '@angular/core';

import {
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatSidenavModule,
    MatListModule,
    MatCardModule,
    MatDatepickerModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatNativeDateModule,
    MatRadioModule,
    MatTabsModule,
    MatCheckboxModule,
    MatTableModule,
    MatSelectModule,
    MatMenuModule,
    MatProgressSpinnerModule,
    MatTooltipModule,
    MatToolbarModule,
    MatSnackBarModule,
    MatTreeModule
} from '@angular/material';

@NgModule({
    imports: [
        MatFormFieldModule,
        MatInputModule,
        MatButtonModule,
        MatButtonToggleModule,
        MatRadioModule,
        MatSidenavModule,
        MatCardModule,
        MatListModule,
        MatExpansionModule,
        MatTabsModule,
        MatIconModule,
        MatGridListModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatCheckboxModule,
        MatTableModule,
        MatSelectModule,
        MatMenuModule,
        MatProgressSpinnerModule,
        MatTooltipModule,
        MatToolbarModule,
        MatSnackBarModule,
        MatTreeModule,
    ],
    exports: [
        MatFormFieldModule,
        MatInputModule,
        MatButtonModule,
        MatButtonToggleModule,
        MatRadioModule,
        MatSidenavModule,
        MatCardModule,
        MatListModule,
        MatExpansionModule,
        MatTabsModule,
        MatIconModule,
        MatGridListModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatCheckboxModule,
        MatTableModule,
        MatSelectModule,
        MatMenuModule,
        MatProgressSpinnerModule,
        MatTooltipModule,
        MatToolbarModule,
        MatTreeModule
    ],
})
export class MaterialModule { }
