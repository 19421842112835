import {Injectable} from '@angular/core';
import {TOKEN_STORAGE_KEY} from '../shared/models/auth.model';

@Injectable({
  providedIn: 'root'
})
export class TokenService {
  constructor() {}

  public get tokenFromStorage(): string {
    return localStorage.getItem(TOKEN_STORAGE_KEY) || '';
  }

  public setToken(token: string): void {
    if (token) {
      localStorage.setItem(TOKEN_STORAGE_KEY, token);
    }
  }

  public removeToken(): void {
    localStorage.removeItem(TOKEN_STORAGE_KEY);
  }
}
