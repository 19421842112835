import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {auth} from '../shared/models/auth.model';
import User = auth.User;
import {Location} from '@angular/common';
import {dic} from '../shared/models/dictionary.model';
import Subservices = dic.Subservices;
import {MatSnackBar} from '@angular/material';
import { Observable } from 'rxjs';
import { Subservice } from '../departments/admin/form-metadata-builder/dynamic-form-metadata-builder.component';

@Injectable({
  providedIn: 'root'
})
export class AdminService {
  
  getMetadata(url: string) {
    return this.api.get2(url);
  }


  getSubserviceList(): Observable<Subservice[]> {
    return this.api.get2('dict/dynamic/subservices');
  }

  saveMetadata(formData: any) {
    return this.api.put2('active-forms/save/metadata', formData);
  }

  constructor(private api: ApiService,
              private location: Location,
              private snackBar: MatSnackBar) {
  }

  pageName: string;
  lastPageNumber: number;

  public goBack() {
    this.location.back();
  }

  public setPaginationInfo(name, nm) {
    this.pageName = name;
    this.lastPageNumber = nm;
  }

  public getPaginationInfo() {
    return {name: this.pageName, number: this.lastPageNumber};
  }

  public setForm(control, data) {
    Object.keys(control)
      .forEach(controlName => {
        if (!control[controlName].controls) {
          if (data) {
            control[controlName].setValue(data[controlName]);
            if (controlName.indexOf('Date') !== -1 || controlName.indexOf('date') !== -1) {
              var convertDate = new Date(data[controlName]).toISOString().substring(0, 10);
              control[controlName].setValue(convertDate, {
                onlyself: true
              });
            }
          }
        } else {
          this.setForm(control[controlName].controls, data[controlName]);
        }
      });
  }

  public openSkackbar(message, action: string = null) {
    this.snackBar.open(message, action, {
      duration: 3000
    });
  }

  public getUsersWithoutPageTasksUsers(body) {
    const url = `tasks/filter/users/assigned/task`;
    return this.api.post2(url, body);
  }

  public getRoleList(page: number = null, size: number = null, name: string = '') {
    return this.api.get2(`roles?page=${page}&size=${size}&name=${name}&sort=id`);
  }

  public addRole(data: any) {
    return this.api.post2('roles', data);
  }

  public getRole(id: number) {
    return this.api.get2(`roles/${id}`);
  }

  public updateRole(role: any) {
    return this.api.put2(`roles/${role.id}`, role);
  }

  public removeRole(id: number) {
    return this.api.delete(`roles/${id}`);
  }

  public removeRoleFromService(id: number, roleId) {
    return this.api.delete(`subservices/${id}/roles/${roleId}`);
  }

  public getUsers(page: number = null, size: number = null, searchText) {
    return this.api.get2(`users?page=${page}&size=${size}&searchText=${searchText}`);
  }

  public getUsersWithoutPage() {
    return this.api.get2(`users`);
  }

  public updateTasksAssignees(body) {
    return this.api.put2(`tasks/assignees`, body);
  }

  public getUserByEmail(email: string) {
    return this.api.get2(`users?username=${email}`);
  }

  public getUserByEmailFromEatyrau(email: string) {
    return this.api.get(`users/email/${email}`);
  }

  public getUserById(id: number) {
    return this.api.get2(`users/${id}`);
  }

  public saveUser(user: User) {
    return this.api.post2('users', user);
  }

  public updateUser(user: User) {
    return this.api.put2(`users/${user.id}`, user);
  }

  public giveUserRoleAndSubservice(data: any) {
    return this.api.post2('users/roles', data);
  }

  public updateUserRoleAndSubservice(data: any, id: number) {
    return this.api.put2(`users/roles/${id}`, data);
  }

  public getUserRoles(username: string) {
    return this.api.get2(`users/roles?username=${username}`);
  }

  public removeUserRole(id: number) {
    return this.api.delete(`users/roles/${id}`);
  }

  public removeUser(id: number) {
    return this.api.delete(`users/${id}`);
  }

  public getSubservices(page: number = null, size: number = null, name: string) {
    return this.api.get2(`subservices?page=${page}&size=${size}&name=${name}`);
  }

  public getMembersById(id: number, regionId: number) {
    if (regionId) {
      return this.api.get2(`subservices/${id}/members?regionId=${regionId}`);
    } else {
      return this.api.get2(`subservices/${id}/members`);
    }

  }

  public getServiceById(id: number) {
    return this.api.get2(`subservices/${id}`);
  }

  public updateSubservice(subservice: Subservices) {
    return this.api.put2(`subservices/${subservice.id}`, subservice);
  }

  public getRoles(subserviceId: number = null) {
    return this.api.get2(`roles?subserviceId=${subserviceId}`);
  }

  public getRoleSubservices(roleId: number) {
    return this.api.get2(`roles/${roleId}/subservices`);
  }

  public removeRoleSubservices(roleId: number, subserviceRoleId: number) {
    return this.api.delete(`/roles/${roleId}/subservices/${subserviceRoleId}`);
  }

  public setSubserviceInRole(data: any, roleId: number) {
    return this.api.post2(`roles/${roleId}/subservices`, data);
  }

  public getAvailableUserSections(userId: number) {
    return this.api.get2(`users/${userId}/access`);
  }

  public getUserMenu() {
    return this.api.get2(`users/access`);
  }

  public saveUserMenu(data: any, userId) {
    return this.api.put2(`users/${userId}/access`, data);
  }

  public getComments() {
    return this.api.get2(`comments`);
  }

  public addComment(comment) {
    return this.api.post2(`comments`, comment);
  }

  public updateComment(id, comment) {
    return this.api.put2(`comments/${id}`, comment);
  }

  public removeComment(id: number) {
    return this.api.delete(`comments/${id}`);
  }

  public getOrganizations() {
    return this.api.get('org/all');
  }

  public getReportTypes() {
    return this.api.get2(`report/types`);
  }

  public getReport(page, size, code, body) {
    return this.api.post2(`report/code/${code}?page=${page}&size=${size}`, body);
  }

  public getReports(page, size, code, body) {
    return this.api.postArrayBuffer(`report/code/${code}/report?page=${page}&size=${size}`, body);
  }

  sortDataByField(data, field) {
    return data.sort((a, b) => (a[field] > b[field]) ? 1 : ((b[field] > a[field]) ? -1 : 0));
  }

  optionSelect(query: any, searchableArr, searchProp: string, searchProp2: string = null, searchProp3: string = null): any[] {
    const result: any[] = [];
    for (const searchableItem of searchableArr) {
      if (searchableItem[searchProp]) {
        if (searchableItem[searchProp].toLowerCase().indexOf(query.toLowerCase()) > -1) {
          result.push(searchableItem);
        }
      }

      if (searchProp2 && searchableItem[searchProp2]) {
        if (searchableItem[searchProp2].toLowerCase().indexOf(query.toLowerCase()) > -1) {
          result.push(searchableItem);
        }
      }
      if (searchProp3 && searchableItem[searchProp3]) {
        if (searchableItem[searchProp3].toLowerCase().indexOf(query.toLowerCase()) > -1) {
          result.push(searchableItem);
        }
      }
    }
    const unique = [...new Set(result)];
    return unique;
  }

  public getAllSchemaProcessesDownload(bpmnKey: string){
    const restPath = `bpmn/scheme/getallprocdefs?bpmnKey=${bpmnKey}`;
    return this.api.getArrayBuffer(restPath);
  }

  public getProcessSchema(page: number, size: number){
    const restPath = `bpmn/scheme/procdefs?page=${page}&size=${size}`;
    return this.api.get2(restPath);
  }

  public getSpecificSchemaProcessVersion(bpmnKey: string, version: number){
    const restPath = `bpmn/scheme/getprocdef?bpmnKey=${bpmnKey}&version=${version}`;
    return this.api.get2(restPath);
  }

  public getSpecificSchemaProcessDownload(bpmnKey: string, version: number){
    const restPath = `bpmn/scheme/getprocdef?bpmnKey=${bpmnKey}&version=${version}`;
    return this.api.getArrayBuffer(restPath);
  }

  public getSpecificSchemaProcess(bpmnKey: string, page: number, size: number, sort: string = null){
    const restPath = `bpmn/scheme/procdefversions?bpmnKey=${bpmnKey}&page=${page}&size=${size}&sort=${sort}`;
    return this.api.get2(restPath);
  }

  public getSubserviceById(id: number) {
    return this.api.get2(`subservices/${id}`);
  }

  public getReassignmentList(processInstanceId, taskDefinitionKey) {
    return this.api.get2(`tasks/getTaskReassignmentLog?processInstanceId=${processInstanceId}&taskInstanceId=${taskDefinitionKey}`);
  }

  public getDirects(page: number = null, size: number = null) {
    return this.api.get2(`universal/dict/references?page=${page}&size=${size}`);
  }

  public getDirect(id) {
    return this.api.get2(`universal/dict/reference/${id}`);
  }

  public getElementOfDirect(id) {
    return this.api.get2(`universal/dict/ref/item?refItemid=${id}`);
  }

  public getElementsOfDirect(code, page) {
    return this.api.get2(`universal/dict/ref/items/page/${code}`, {page, size: 10});
  }

  public addNewDirect(data) {
    return this.api.post2(`universal/dict/references`, data);
  }

  public addNewDirectElement(data) {
    return this.api.post2(`universal/dict/ref/items`, data);
  }

  public updateDirect(data) {
    return this.api.put2(`universal/dict/references`, data);
  }

  public updateDirectElement(data) {
    return this.api.put2(`universal/dict/ref/items`, data);
  }

  public getFiles(page: number, code = null, name = null) {
    return this.api.getFile(`/fileCategory/all?page=${page}&size=10`, {code, name});
  }

  public deleteFiles(code: string) {
    return this.api.deleteFile(`/fileCategory/${code}`);
  }

  public addNewFile(data) {
    return this.api.getFile(`/fileCategory`, data);
  }

  public updateFile(data) {
    return this.api.putFile(`/fileCategory`, data);
  }
}
