import {Observable, of, Subscriber} from 'rxjs';

export const VOID: Observable<void> = new Observable<void>((subscriber: Subscriber<void>) => {
  subscriber.next();
  subscriber.complete();
});

export const NULL$: Observable<null> = of(null);

export const TRUE$: Observable<boolean> = of(true);

export const FALSE$: Observable<boolean> = of(false);
