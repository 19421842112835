import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MatDialogRef} from '@angular/material';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {Observable, Subject, timer} from 'rxjs';
import {map, takeUntil} from 'rxjs/operators';

@Component({
  selector: 'app-message-popup',
  templateUrl: './message-popup.component.html',
  styleUrls: ['./message-popup.component.scss']
})
export class MessagePopupComponent implements OnInit, OnDestroy {
  public message: string;
  public closeButtonActive: boolean;
  private readonly destroyed$: Subject<void>;
  private readonly messageKey: string;
  private readonly closeButtonToggleKey: string;
  private readonly componentCloseTimeout: number;

  constructor(
    private dialogRef: MatDialogRef<MessagePopupComponent>,
    @Inject(MAT_DIALOG_DATA)
    private dialogData: any,
  ) {
    this.destroyed$ = new Subject<void>();
    this.messageKey = 'message';
    this.closeButtonToggleKey = 'closeButtonToggle';
    this.componentCloseTimeout = 3000;
    this.message = '';
    this.closeButtonActive = true;
  }

  public ngOnInit(): void {
    this.getMessage();
    this.checkCloseButtonToggleStatus();
  }

  public ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  public close(): void {
    this.dialogRef.close();
  }

  private getMessage(): void {
    if (this.dialogData && this.dialogData[this.messageKey]) {
      const message: any = this.dialogData[this.messageKey];
      if (message instanceof Observable) {
        message
          .pipe(
            takeUntil(this.destroyed$)
          )
          .subscribe(
            (text: string) => {
              this.message = text;
            }
          );
      } else if (typeof message === 'string') {
        this.message = message;
      } else {
        this.message = message.toString();
      }
    }
  }

  private checkCloseButtonToggleStatus(): void {
    if (
      this.dialogData &&
      this.dialogData[this.closeButtonToggleKey] &&
      this.dialogData[this.closeButtonToggleKey] instanceof Observable
    ) {
      this.dialogData[this.closeButtonToggleKey]
        .pipe(
          map((value: any) => !!value),
          takeUntil(this.destroyed$)
        )
        .subscribe(
          (status: boolean) => {
            this.closeButtonActive = status;
            if (status) {
              this.setCloseTimer();
            }
          }
        );
    }
  }

  private setCloseTimer(): void {
    timer(this.componentCloseTimeout)
      .pipe(
        takeUntil(this.destroyed$)
      )
      .subscribe(
        () => {
          this.dialogRef.close();
        }
      );
  }
}
