import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {ProblemService} from '../../services/problem.service';

@Component({
  selector: 'app-create-task',
  templateUrl: './create-task.component.html',
  styleUrls: ['./create-task.component.scss']
})
export class CreateTaskComponent implements OnInit, OnDestroy {
  currentTasks: number;
  finishedTasks: number;
  subscription: Subscription;

  constructor(
    private taskService: ProblemService
  ) {
    this.subscription = this.taskService.getMenu().subscribe(menu => {
      this.getSectionCount(menu.data);
    });
  }

  ngOnInit() {
  }

  getSectionCount(menu) {
    if (menu) {
      this.finishedTasks = menu.finishedTasks;
      this.currentTasks = menu.currentTasks;
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
