import {Nullable} from './types.model';

export const TOKEN_STORAGE_KEY = 'access_token';
export const ASYNC_STORAGE_LOGOUT_KEY = 'asyncStorageLogout';

export interface UserNamePrefix {
  readonly value: string;
  readonly length: number;
}
export namespace auth {
  export class User {
    constructor(
      public active: boolean = false,
      public blocked: boolean = false,
      public id: number = null,
      public firstName: string = '',
      public lastName: string = '',
      public secondName: string = '',
      public iin: string = '',
      public bin: any = null,
      public orgName: any = null,
      public organization: any = null,
      public userType: any = null,
      public username: string = '',
      public link: any = null,
      public positionKk: any = null,
      public positionRu: any = null,
      public roles: any = null,
      public status: any = null,
      public email: string = '',
      public regionId: any = null,
      public actPassChanged: boolean = false,
      public passChangeLastDate: Date | string | null = null,
    ) {
    }
  }
}

export type AuthToken = Nullable<string>;

export type AuthTokenData = Nullable<JwtPayload>;

export type AuthUser = Nullable<auth.User>;

export type UserAuthorities = Nullable<string[]>;

export interface AsyncStorageLogoutData {
  token: string;
  expires: number;
}

export interface AuthLogoutParams {
  redirect?: boolean;
  serverLogout?: boolean;
  asyncLogout?: boolean;
  expires?: number;
}

export function isAsyncStorageLogoutData(data: any): data is AsyncStorageLogoutData {
  return (
    !!data
    && typeof data === 'object'
    && typeof data.token === 'string'
    && typeof data.expires === 'number'
  );
}

export interface JwtPayload {
  readonly allowGeoportal: Nullable<boolean>;
  readonly firstName: Nullable<string>;
  readonly lastName: Nullable<string>;
  readonly user_name: Nullable<string>;
  readonly scope: Nullable<any[]>;
  readonly exp: Nullable<number>;
  readonly userId: Nullable<number>;
  readonly authorities: Nullable<string[]>;
  readonly jti: Nullable<string>;
  readonly email: Nullable<string>;
  readonly client_id: Nullable<string>;
  readonly username: Nullable<string>;
}
