export const LANGUAGES = ['ru', 'kk', 'qz'] as const;

export const DEFAULT_LANGUAGE: Language = 'kk';

export const CURRENT_LANGUAGE_KEY = 'currentLang';

export const LANGUAGE_PARAM_MAP: LanguageMap<string, Language> = {
  ru: 'ru',
  kz: 'kk',
  qaz: 'qz'
};

export type Language = typeof LANGUAGES[number];

export type LanguageLike = Language | string;

export type LanguageMap<K extends string, V extends string> = {
  [key in K]: V;
};

export type TranslationMap = {
  [language in Language]: string;
};

export interface TranslationWrapper {
  [key: string]: string | TranslationWrapper;
}

export interface TranslationPair {
  key: string;
  value: string;
}
