import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {LoginComponent} from '../../core/auth/login/login.component';
import {MatDialog} from '@angular/material';
import {AuthService} from '../../services/auth.service';
import {ROLES} from '../../shared/utils/constants';
import {TranslateService} from '@ngx-translate/core';
import {Router} from '@angular/router';
import {auth} from '../../shared/models/auth.model';
import {RedirectService} from '../../services/redirect.service';
import {takeUntil} from 'rxjs/operators';
import {DestroyableComponent} from '../../shared/components/destroyable/destroyable.component';
import { environment } from '@environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HeaderComponent extends DestroyableComponent implements OnInit {
  isRoleArch = false;
  isRoleOZO = false;
  isRoleDutyMap = false;
  isRoleCom = false;
  currentUser = null;
  currentISOUser: auth.User = null;
  currentLang;

  constructor(
    private dialog: MatDialog,
    private authService: AuthService,
    private translate: TranslateService,
    private router: Router,
    private redirectService: RedirectService
  ) {
    super();
    this.currentLang = this.translate.currentLang ? this.translate.currentLang : this.translate.getDefaultLang();
  }

  ngOnInit() {
    this.getUserInfo();
    this.getCurrentUserData();
    this.initTranslate();
  }

  private getUserInfo() {
    this.authService.getUserInfo()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((data: any) => {
        if (data) {
          this.currentUser = data;
        } else {
          this.currentUser = null;
        }
      });
  }

  initTranslate() {
    this.translate.onLangChange
      .pipe(takeUntil(this.destroyed$))
      .subscribe((event: any) => {
        this.currentLang = event.lang;
      });
  }

  getCorrectUsername() {
    if (this.currentISOUser) {
      const result = `${this.currentISOUser.lastName} ${this.currentISOUser.firstName}`;
      return result;
    }
    if (this.currentUser) {
      const result = `${this.currentUser.lastName} ${this.currentUser.firstName}`;
      return result;
    }
  }

  getUsername() {
    if (this.currentUser) {
      return this.currentUser.username;
    }
  }

  getUserIIN() {
    if (this.currentISOUser) {
      const iin = this.currentISOUser.iin ? `${this.currentISOUser.iin} |` : '';
      const bin = this.currentISOUser.bin ? `${this.currentISOUser.bin} |` : '';
      const result = `${bin || iin}`;
      return result;
    }
    if (this.currentUser) {
      const iin = this.currentUser.iin ? `${this.currentUser.iin} |` : '';
      const bin = this.currentUser.bin ? `${this.currentUser.bin} |` : '';
      const result = `${bin || iin}`;
      return result;
    }
  }

  login(): void {
    const dialogRef = this.dialog.open(LoginComponent, {
      width: '500px',
      data: {}
    });
  }

  private getCurrentUserData() {
    this.authService
      .getUserInfo()
      .pipe(
        takeUntil(this.destroyed$)
      )
      .subscribe(
        (data: any) => {
          this.currentISOUser = data;
        }
      );
  }

  logout() {
    this.authService.logout();
    this.changeRoute();
  }

  changeRoute() {
    this.router.navigate(['/']);
  }

  switchLang(lang: string): any {
    this.translate.use(lang);
    localStorage.setItem('currentLang', lang);
  }

  getPhoneInfo() {
    if (this.currentLang === 'kk') {
      return 'Бірыңғай байланыс орталығы: 109, ' +
        'Жұмыс уақыты: дүйсенбіден жұмаға дейін 9.00-ден 18.30-ға дейін, түскі үзіліс 13.00 - 14.30. ';
    }
    if (this.currentLang === 'ru') {
      return `Единый контакт-центр: 109, Рабочее время: пн-пт с 9.00 до 18.30 перерыв с 13.00 до 14.30.`;
    }
  }


  checkCurrentUserRoles() {
    this.isRoleDutyMap = this.authService.hasRole(ROLES.DUTY_MAP);
    this.isRoleArch = this.authService.hasRole(ROLES.BPM_ARCH);
    this.isRoleCom = this.authService.hasRole(ROLES.BPM_COM);
    if (!this.isRoleArch) {
      this.isRoleOZO = this.authService.hasRole(ROLES.BPM_OZO);
    }
  }

  public redirectToEServices(): void {
    this.redirectService.redirectToProject({
      url: `${environment.project.urls.default.es}`,
      testAccess: true
    });
  }
}
