import {HttpClient, HttpHeaders, HttpResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {CONST} from '../config';
import { map } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class ApiService {
  private readonly destroyed$: Subject<void>;

  constructor(private http: HttpClient) {
  }
  public getWithoutAuth(url: string, parms?): Observable<any> {
    return this.http.get(url, {
      params: parms,
    });
  }

  public post2xml(url_path: string, body: any = null, parms?, auth = true): Observable<HttpResponse<any>> {
    const url: string = CONST.CONFIG.SERVICES_URL_2 + url_path;
    const headers = auth ? {
      'Content-Type': 'application/json'
    } : {};
    return this.http.post<any>(encodeURI(url), body, {
      headers: new HttpHeaders(headers),
      observe: 'response',
      params: parms,
      responseType: 'text' as 'json'
    });
  }

  public get(restPath: string, parms?, auth = true): Observable<any> {
    const url: string = CONST.CONFIG.SERVICES_URL + restPath;
    const token = localStorage.getItem('access_token');
    const headers = auth ? {
      'Authorization': 'Bearer ' + token
    } : {};
    return this.http.get(url, {
      headers: new HttpHeaders(headers),
      params: parms,
    });
  }

  public get5(restPath: string, parms?, auth = true): Observable<any> {
    const url: string = CONST.CONFIG.DUTYMAP_SERVICES_URL + restPath;
    const token = localStorage.getItem('access_token');
    const headers = auth ? {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json'
    } : {};
    return this.http.get(url, {
      headers: new HttpHeaders(headers),
      params: parms,
    });
  }

  public getWithAuth(restPath: string, parms?, auth = true): Observable<any> {
    const token = localStorage.getItem('access_token');
    const headers = auth ? {
      'Authorization': 'Bearer ' + token
    } : {};
    return this.http.get(restPath, {
      headers: new HttpHeaders(headers),
      params: parms,
    });
  }

  public post_auth(restPath: string, body: any = null, parms?, auth: boolean = true): Observable<any> {
    const url: string = CONST.CONFIG.AUTH_URL + restPath;
    const token = localStorage.getItem('access_token');
    const headers = auth ? {
      'Authorization': 'Bearer ' + token,
      'Content-Type': 'application/json'
    } : {};
    return this.http.post<any>(url, body, {
      headers: new HttpHeaders(headers),
      observe: 'response',
      params: parms
    });
  }

  public get2(restPath: string, parms?, auth = true): Observable<any> {
    const url: string = CONST.CONFIG.SERVICES_URL_2 + restPath;
    const token = localStorage.getItem('access_token');
    const headers = auth ? {
      'Authorization': 'Bearer ' + token
    } : {};
    return this.http.get(url, {
      headers: new HttpHeaders(headers),
      params: parms,
    });
  }

  /* public getArrayBuffer(url_path: string, params: any = null): Observable<HttpResponse<any>> {
     const url: string = CONST.CONFIG.SERVICES_URL_2 + url_path;
     const httpHeaders = new HttpHeaders({
       'Content-Type': 'application/json'
     });
     return this.http.get<any>(url, {
       responseType: 'blob' as 'json',
     });
   }*/

  public getArrayBuffer(url_path: string): Observable<HttpResponse<any>> {
    const url: string = CONST.CONFIG.SERVICES_URL_2 + url_path;
    const token = localStorage.getItem('access_token');
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    });
    return this.http.get<any>(url, {
      responseType: 'blob' as 'json',
      headers: httpHeaders
    });
  }

  public post(url_path: string, body: any = null, parms?, auth = true): Observable<HttpResponse<any>> {
    const url: string = CONST.CONFIG.SERVICES_URL + url_path;
    const token = localStorage.getItem('access_token');
    const headers = auth ? {
      'Authorization': 'Bearer ' + token,
      'Content-Type': 'application/json'
    } : {};
    return this.http.post<any>(url, body, {
      headers: new HttpHeaders(headers),
      observe: 'response',
      params: parms
    });
  }

  public post2(url_path: string, body: any = null, parms?, auth = true): Observable<HttpResponse<any>> {
    const url: string = CONST.CONFIG.SERVICES_URL_2 + url_path;
    const token = localStorage.getItem('access_token');
    const headers = auth ? {
      'Authorization': 'Bearer ' + token,
      'Content-Type': 'application/json'
    } : {};
    return this.http.post<any>(url, body, {
      headers: new HttpHeaders(headers),
      observe: 'response',
      params: parms
    });
  }

  public postArrayBuffer(url_path: string, body: any = null, params: any = null, auth = true): Observable<HttpResponse<any>> {
    const url: string = CONST.CONFIG.SERVICES_URL_2 + url_path;
    const token = localStorage.getItem('access_token');
    const headers = auth ? {
      'Authorization': 'Bearer ' + token,
      'Content-Type': 'application/json'
    } : {};
    return this.http.post<any>(url, body, {
      headers: new HttpHeaders(headers),
      responseType: 'blob' as 'json',
      params
    });
  }

  public put(url_path: string, body: any, params: any = null): Observable<HttpResponse<any>> {
    const url: string = CONST.CONFIG.SERVICES_URL + url_path;
    const token = localStorage.getItem('access_token');
    return this.http.put<any>(url, body, {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + token,
        'Content-Type': 'application/json'
      }),
      observe: 'response'
    });
  }

  public put2(url_path: string, body: any = null, params: any = null): Observable<HttpResponse<any>> {
    const url: string = CONST.CONFIG.SERVICES_URL_2 + url_path;
    const token = localStorage.getItem('access_token');
    return this.http.put<any>(url, body, {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + token,
        'Content-Type': 'application/json',
        siteUrl: window.location.href
      }),
      observe: 'response'
    });
  }

  public delete(restPath: string, parms?): Observable<any> {
    const url: string = CONST.CONFIG.SERVICES_URL_2 + restPath;
    const token = localStorage.getItem('access_token');
    return this.http.delete(url, {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + token,
        'Content-Type': 'application/json'
      }),
      params: parms,
      observe: 'response'
    });
  }


  public post5(url_path: string, body: any = null, parms?: any, auth = true): Observable<Blob> {
    const url: string = CONST.CONFIG.SERVICES_URL_2 + url_path;
    const token = localStorage.getItem('access_token');
    const headers = auth ? {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json'
    } : {};
    return this.http.post(url, body, {
      headers: new HttpHeaders(headers),
      observe: 'response',
      params: parms,
      responseType: 'blob' // This tells the HttpClient to handle the response as a Blob
    }).pipe(
      map((res: any) => res.body) // Extract the Blob from the HttpResponse
    );
  }

  public getFile(url_path: string, body: any = null, parms?, auth = true): Observable<HttpResponse<any>> {
    const url: string = CONST.CONFIG.FILESERVER_URL + url_path;
    const token = localStorage.getItem('access_token');
    const headers = auth ? {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json'
    } : {};
    return this.http.post<any>(url, body, {
      headers: new HttpHeaders(headers),
      observe: 'response',
      params: parms
    });
  }

  public putFile(url_path: string, body: any = null, params: any = null): Observable<HttpResponse<any>> {
    const url: string = CONST.CONFIG.FILESERVER_URL + url_path;
    const token = localStorage.getItem('access_token');
    return this.http.put<any>(url, body, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json',
        siteUrl: window.location.href
      }),
      observe: 'response'
    });
  }

  public deleteFile(restPath: string, parms?): Observable<any> {
    const url: string = CONST.CONFIG.FILESERVER_URL + restPath;
    const token = localStorage.getItem('access_token');
    return this.http.delete(url, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json'
      }),
      params: parms,
      observe: 'response'
    });
  }
}
