import {auth} from './auth.model';
import {app} from './application.model';
import {FileApiWrapper} from './file.model';
import {Nullable} from './types.model';

// tslint:disable-next-line:no-namespace
export namespace dic {
  import User = auth.User;

  export class Phase {
    constructor(
      public id: number = 1,
      public nameRu: string = '',
      public nameKk: any = null,
      public nameEn: any = null
    ) {
    }
  }

  export class Role {
    constructor(
      public id: any = null,
      public nameRu: string = '',
      public nameKk: string = '',
      public nameEn: string = '',
      public name: string = '',
      public shortNameRu: string = '',
      public shortNameKk: string = '',
      public shortNameEn: string = '',
      public description: string = ''
    ) {
    }
  }

  export class Problem {
    constructor(
      public id: any = null,
      public name: string = '',
      public assignee: string = '',
      public formKey: any = null,
      public content: any = null,
      public timerData: TimerData = new TimerData(),
    ) {
    }
  }

  export class Decide {
    constructor(
      public approved: boolean = null,
      public agreed: boolean = null,
      public message: string = '',
      public subserviceId: number = null,
      public files: any = [],
      public from: string = null,
      public internalFiles: any = [],
      public signedDocument: any = null,
      public communalSigned: boolean = null,
      public commissionResult: string = null,
      public commissionText: string = '',
      public orderText: string = null,
      public lastAddedUserTexts: string = null,
      public lingual: boolean = false,
      public jurist: boolean = false,
      public apparat: boolean = false,
      public akt: boolean = false,
      public orgAgreed: boolean = false,
      public zamakim: boolean = false,
      public rukozo: boolean = false,
      public rukotdel: boolean = false,
      public headengineer: boolean = false,
      public rukupr: boolean = false,
      public slujbaex: boolean = false,
      public nach: boolean = false,
      public akim: boolean = false,
      public rukarch: boolean = false,
      public headpto: boolean = false,
      public rukovoditel: boolean = false,
      public headltc: boolean = false,
      public headkc: boolean = false,
      public director: boolean = false,
      public nachotdel: boolean = false,
      public reg: boolean = false,
      public rework: boolean = false,
      public kanc: boolean = true,
      public chancellery: string = '',
      public number: string = null,
      public date: Date = new Date(),
      public scan: string = null,
      public scanFiles: any = [],
      public zkFiles: any = [],
      public contractNumber: string = null,
      public contractDate: Date = new Date(),
      public contractDueDate: Date = new Date(),
      public owner: string = '',
      public control: string = '',
      public controlName: string = '',
      public executor: string = '',
      public executorName: string = '',
      public comments: any = [],
      public suggestions: any = [],
      public recomendation: string = '',
      public zemkom: boolean = null,
      public spr_agreed: boolean = null,
      public pto_agreed: boolean = null,
      public proiz_agreed: boolean = null,
      public ins_agreed: boolean = null,
      public eks_agreed: boolean = null,
      public first_vice_agreed: boolean = null,
      public jurist_check: boolean = false,
      public role: string = '',
      public revoked: boolean = false,
      public kulsaryJusanMobile: boolean = false,
      public kulsaryKabelCekh: boolean = false,
      public kulsaryZhylyoiSu: boolean = false,
      public kulsaryAtyrauZharyk: boolean = false,
      public kulsaryTranstelecom: boolean = false,
      public kulsaryKaspiBassein: boolean = false,
      public kulsaryZhylyoiGaz: boolean = false,
      public kulsaryKazakhtelecom: boolean = false,
      public transTelecom: boolean = false,
      public to_tusm: boolean = false,
      public to_pto: boolean = null,
      public to_hozyaistvo: boolean = false,
      public tusm: boolean = false,
      public pto: boolean = false,
      public communalOrganizationId: number = null,
      public appId: number | null = null,
      public to_pts: boolean | null = null,
      public to_sks: boolean | null = null,
      public to_slap: boolean | null = null,
      public to_jurist: boolean | null = null,
    ) {
    }
  }

  export class TaskData {
    constructor(
      public name: string = null,
      public assignee: string = null,
      public owner: string = 'arch',
      public dueDate: Date = new Date(),
      public processInstanceId: any = null,
      public content = new TaskContent(),
      public id: number = null
    ) {
    }
  }

  export class TaskForRefresh {
    constructor(
      public executor: string = null,
      public owner: string = 'arch',
      public dueDate: any = new Date(),
      public control: any = null,
      public executorName: string = null,
      public controlName: string = null
    ) {
    }
  }

  export class QuestionBase<T> {
    value: T;
    key: string;
    label: string;
    required: boolean;
    order: number;
    controlType: string;
    type: string;
    options: { key: string, value: string }[];
    disabled: boolean;

    constructor(options: {
      value?: T;
      key?: string;
      label?: string;
      required?: boolean;
      order?: number;
      controlType?: string;
      type?: string;
      disabled?: boolean;
      options?: { key: string, value: string }[];
    } = {}) {
      this.value = options.value;
      this.key = options.key || '';
      this.label = options.label || '';
      this.required = !!options.required;
      this.order = options.order === undefined ? 1 : options.order;
      this.controlType = options.controlType || '';
      this.type = options.type || '';
      this.options = options.options || [];
      this.disabled = !!options.disabled;
    }
  }

  export class AuctionOrg {
    constructor(
      public position: number = null,
      public name: string = '',
      public code: string = null,
      public file?: Nullable<FileApiWrapper>
    ) {
    }
  }

  export class TaskContent {
    constructor(
      public approved: boolean = null,
      public agreed: boolean = null,
      public appId: number = null,
      public type: string = null,
      public chancellery:any = null,
      public message: any = null,
      public registerDate: any = new Date(),
      public registerNumber: any = null,
      public dueDate: any = null,
      public planEndDate: any = null,
      public control: any = null,
      public executor: string = '',
      public files: any = null,
      public executorName: string = null,
      public controlName: string = null,
      public from: string = null,
      public internalFiles: any = null,
      public signedDocument: boolean = null,
      public subserviceType: string = null,
      public org: string = null,
      public role: string = null,
      public subserviceId: any = null,
      public communalOrganizationId: any = null,
      public code: string = null
    ) {
    }
  }

  export class TemplateEditor {
    constructor(
      public id: number = null,
      public approved: boolean = null,
      public nameKk: string = null,
      public nameRu: string = null,
      public text: string = '',
      public subserviceId: number = null,
      public orgId: number = 1,
      public regionId: Nullable<number> = null,
      public uniqueCode?: Nullable<string>,
      public fileCategory?: Nullable<string>
    ) {
    }
  }

  export interface TemplateFileCategory {
    readonly name: Nullable<string>;
    readonly description: Nullable<string>;
  }

  export class SubjectTemplateEditor {
    constructor(
      public id: number = null,
      public nameKk: string = null,
      public nameRu: string = null,
      public text: string = null,
      public contractSubject: any = {},
    ) {
    }
  }

  export class Service {
    constructor(
      public id: number = null,
      public nameRu: string = '',
      public nameKk: string = '',
      public nameEn: string = '',
      public shortNameKk: string = '',
      public shortNameRu: string = '',
      public shortNameEn: string = '',
      public code: string = ''
    ) {
    }
  }

  export class Subservices {
    constructor(
      public id: number = null,
      public nameRu: string = '',
      public nameKk: string = '',
      public nameEn: string = '',
      public shortNameKk: string = '',
      public shortNameRu: string = '',
      public shortNameEn: string = '',
      public service: Service = new Service(),
      public days: number = null,
      public workdaysOnly: boolean = false,
      public code: any = null
    ) {
    }
  }

  export class CategoryFiles {
    constructor(
      public id: number = null,
      public categoryFiles: any = [],
      public categoryFilesUpload: any = [],
      public extensions: any = null,
      public required: boolean = false,
      public subserviceId: number = null,
      public title: string = '',
      public titleKk: string = '',
      public titleRu: string = '',
      public type: string = 'app'
    ) {
    }
  }

  export class UserRoles {
    constructor(
      public head: any = null,
      public executor: any = null,
      public controller: any = null,
    ) {
    }
  }

  export class Correspondence {
    constructor(
      public id: number = null,
      public regNumber: number = null,
      public type: string = '',
      public executeDueDate: Date = new Date(),
      public sender: string = '',
      public executable: boolean = false,
      public executor: User = new User(),
      public regDate: Date = new Date(),
      public executed: string = '',
      public body: string = '',
      public receiver: string = '',
      public outNumber: string = '',
      public outDate: Date = new Date(),
      public outBody: string = '',
      public category: any = null,
      public files: any = [],
    ) {
    }
  }

  export class AdmDocument {
    constructor(
      public id: number = null,
      public author: string = null,
      public nameRu: string = '',
      public nameEn: string = '',
      public nameKk: string = '',
      public employee: User = new User(),
      public date: Date = new Date(),
      public body: string = '',
      public category: any = null
    ) {
    }
  }

  export class PhasePeriod {
    constructor(
      public id: number = 1,
      public nameRu: string = '',
      public nameKk: any = null,
      public nameEn: any = null
    ) {
    }
  }

  export class Contract {
    constructor(
      public id: any = null,
      public subject: Subject = new Subject(),
      public number: number = null,
      public date: Date = new Date(),
      public dueDate: Date = new Date(),
      public createDate: Date = new Date(),
      public sum: number = null,
      public customer: Customer = new Customer(),
      public description: string = '',
      public author: string = '',
      public body: string = '',
      public prevContract: Contract = null
    ) {
    }
  }

  export class MonitoringContract {
    constructor(
      public id: any = null,
      public subject: Subject = new Subject(),
      public number: number = null,
      public date: Date = new Date(),
      public dueDate: Date = new Date(),
      public createDate: Date = new Date(),
      public sum: number = null,
      public customer: Customer = new Customer(),
      public description: string = '',
      public author: auth.User = null,
      public body: string = '',
      public prevContract: Contract = null,
      // tslint:disable-next-line:no-shadowed-variable
      public app: app.App = null,
      public mainLateDays: Date = null
    ) {
    }
  }

  export class Subject {
    constructor(
      public id: number = null,
      public nameRu: string = '',
      public nameKk: string = '',
      public nameEn: string = '',
      public subjectType: string = ''
    ) {
    }
  }

  export class Customer {
    constructor(
      public id: number = null,
      public firstName: string = '',
      public lastName: string = '',
      public secondName: string = '',
      public iin: number = null
    ) {
    }
  }

  export class ContractExecution {
    constructor(
      public id: any = null,
      public contract: Contract = new Contract(),
      public comment: string = '',
      public dueDate: Date = new Date(),
      public factSum: string = '',
      public sum: number = null,
      public specialText: string = '',
      public executed: boolean = false
    ) {
    }
  }

  export class Organization {
    constructor(
      public id: number = null,
      public name: string = '',
      public nameRu: string = '',
      public nameKk: string = '',
      public orgType: any = null,
      public ozoConfirmer: boolean = false,
      public regionId: number = 1,
      public regionNameKk: string = '',
      public regionNameRu: string = '',
      public shortName: string = null,
      public shortNameEn: string = null,
      public shortNameKk: string = null,
      public shortNameRu: string = null,
      public headUserName: string = '',
      public headUserPositionKk: string = '',
      public headUserPositionRu: string = '',
      public headUserShortName: string = '',
      public communal: any = null
    ) {
    }
  }


  export class Organizations {
    constructor(
      public id: Nullable<number> = null,
      public appOrgFiles: Nullable<any[]> = [],
      public appOrgId: Nullable<number> = null,
      public code: Nullable<string> = '',
      public connections: Nullable<string> = '',
      public hasTechCondition: Nullable<boolean> = false,
      public name: Nullable<string> = '',
      public responseDate: Nullable<string> = null,
      public sendDate: Nullable<string> = null,
      public sent: Nullable<boolean> = false,
      public status: Nullable<string> = null,
      public signed: Nullable<boolean> = null,
      public fileCategory?: Nullable<string>,
      public fileName?: string
    ) {
    }
  }

  export class FilterTable {
    constructor(
      public key: string = '',
      public operation: string = '',
      public value: any = '',
      public value2: any = null,
      public type: string = 'string'
    ) {
    }
  }

  export class TimerData {
    constructor(
      public days: number = null,
      public hours: number = null,
      public minutes: number = null,
      public seconds: number = null,
    ) {
    }
  }

}
