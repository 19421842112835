import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {LANGUAGE_QUERY_PARAM, REDIRECT_URL_KEY} from '../../../shared/models/redirect.model';
import {TOKEN_STORAGE_KEY} from '../../../shared/models/auth.model';
import {LocalizationService} from '../../../services/localization/localization.service';
import {AuthTokenService} from "../../../services/auth-token.service";

@Component({
    selector: 'app-redirect',
    template: 'redirecting...'
})
export class RedirectComponent implements OnInit {

    constructor(private tokenService: AuthTokenService,
                private l10nService: LocalizationService,
                private route: ActivatedRoute,
                private router: Router) {
    }

    ngOnInit() {
        this.route.queryParams.subscribe(params => {
            const queryParams = {...params};

            const redirectUrl = params[REDIRECT_URL_KEY];
            const token = params[TOKEN_STORAGE_KEY];
            const lang = params[LANGUAGE_QUERY_PARAM];

            if (token) {
                this.tokenService.setToken(token);
                delete queryParams[TOKEN_STORAGE_KEY];
            }

            if (lang) {
                this.l10nService.setCurrentLanguage(lang);
                delete queryParams[LANGUAGE_QUERY_PARAM];
            }

            if (redirectUrl) {
                delete queryParams[REDIRECT_URL_KEY];
                this.router.navigate([decodeURIComponent(redirectUrl)], {queryParams});
            }
        });
    }
}
