import { HttpClient } from "@angular/common/http";
import { Component, OnDestroy, OnInit } from "@angular/core";
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
} from "@angular/material/dialog";
import { AuthService } from "../../../services/auth.service";
import { auth, TOKEN_STORAGE_KEY, UserNamePrefix } from "../../../shared/models/auth.model";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MustMatch } from "../../../shared/helper/must-match.validator";
import { MatSnackBar } from "@angular/material";
import { Router } from "@angular/router";
import { switchMap, takeUntil, timeout } from "rxjs/operators";
import { Observable, Subject } from "rxjs";
import { DateType, getUTCTimestamp } from "src/app/shared/utils/time-date.utils";
import { HTTP_REQUEST_TIMEOUT, TIME_UNITS } from "src/app/shared/utils/constants";
import { ResetPasswordComponent } from "../reset-password/reset-password.component";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit, OnDestroy {
  message = "";
  authForm: FormGroup;
  hidePassword = true;
  registerContent: boolean;
  registerForm: FormGroup;
  submitted = false;
  private destroyed$ = new Subject<void>();
  private readonly IINUsernamePrefix: UserNamePrefix;
  private readonly BINUsernamePrefix: UserNamePrefix;

  constructor(
    private dialog: MatDialog,
    private http: HttpClient,
    private authService: AuthService,
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
    private router: Router
  ) {}

  ngOnInit() {
    this.initForm();
  }

  private initForm() {
    this.authForm = this.fb.group({
      username: ["", [Validators.required, Validators.minLength(3)]],
      password: ["", [Validators.required, Validators.minLength(3)]],
    });
  }

  private initRegisterForm() {
    if (!this.registerForm) {
      this.registerForm = this.fb.group(
        {
          email: [
            "",
            [Validators.required, Validators.minLength(3), Validators.email],
          ],
          username: [""],
          password: ["", [Validators.required, Validators.minLength(3)]],
          confirmPassword: ["", [Validators.required, Validators.minLength(3)]],
          firstName: ["", [Validators.required]],
          lastName: [""],
          secondName: ["", [Validators.required]],
        },
        {
          validator: MustMatch("password", "confirmPassword"),
        }
      );
    }
  }

  private fakeLogin(): void {
    this.authForm = this.fb.group({
      username: ["a.beisova", [Validators.required, Validators.minLength(3)]],
      password: [
        "Mfy3th4xP8%V9%hD",
        [Validators.required, Validators.minLength(3)],
      ],
    });
    this.toLogin();
  }

  public toLogin() {
    if (this.authForm.invalid) {
      this.authForm.markAllAsTouched();
      return;
    }
    this.http.post("/auth/token", this.authForm.value)
    .pipe(
      timeout(HTTP_REQUEST_TIMEOUT),
      takeUntil(this.destroyed$)
    )
    .subscribe(
      (data: any) => {
        if (data && data.access_token) {
          this.onSuccessLogin(data);
        } else {
          console.log(this.message)
          this.message = "something went wrong";
        }
      },
      (error: any) => {
        console.log(error);
        if (error && error.error && error.error.error) {
          this.message = error.error.error;
        }
      }
    );
  }

  private static datesDifferenceExceeds(
    date1: DateType,
    date2: DateType,
    days: number = 90
  ): boolean | null {
    if (date1 && date2) {
      const timestamp1: number = getUTCTimestamp(date1);
      const timestamp2: number = getUTCTimestamp(date2);
      if (Number.isFinite(timestamp1) && Number.isFinite(timestamp2)) {
        const diff: number = Math.floor(
          Math.abs(timestamp1 - timestamp2)
          / TIME_UNITS.MILLISECONDS
          / TIME_UNITS.SECONDS
          / TIME_UNITS.MINUTES
          / TIME_UNITS.HOURS
        );
        return diff > days;
      }
    }
    return null;
  }

  onSuccessLogin(data) {

    this.authService
      .setAuthorization(data[TOKEN_STORAGE_KEY])
      .pipe(
        switchMap(() => this.authService.getUserInfo()),
        takeUntil(this.destroyed$)
      )
      .subscribe((user: auth.User | null) => {
        if (user) {
          const username: string | null = user.username;
          const currentDate: Date = new Date();
          let loggedAsEDS = false;
          if (username) {
            const IINPrefix: string = username.slice(0, this.IINUsernamePrefix.length);
            const BINPrefix: string = username.slice(0, this.BINUsernamePrefix.length);
            loggedAsEDS = IINPrefix === this.IINUsernamePrefix.value || BINPrefix === this.BINUsernamePrefix.value;
          }
          if (
            user.actPassChanged !== true
            || LoginComponent.datesDifferenceExceeds(user.passChangeLastDate, currentDate) !== false
          ) {
            if (!loggedAsEDS) {
              this.modalResetPasswordShow();
            }
            return;
          }
        }
      });




    // localStorage.setItem("access_token", data.access_token);
    // this.authService.init();
    // this.authService.getCurrentUser().subscribe(
    //   (user: auth.User) => {
    //     if (user) {
    //       this.authService.setUserInfo(user);
    //     } else {
    //       this.authService.setUserInfo(null);
    //     }
    //   },
    //   (error) => {
    //     console.log(error);
    //   }
    // );
  }

  get login() {
    return this.authForm.controls.username;
  }

  get password() {
    return this.authForm.controls.password;
  }

  get confirmPassword() {
    return this.registerForm.controls.confirmPassword;
  }

  get registerLogin() {
    return this.registerForm.controls.email;
  }

  get registerPassword() {
    return this.registerForm.controls.password;
  }

  private modalResetPasswordShow(): void {
    const dialogRef = this.dialog.open(ResetPasswordComponent, {
      width: '500px',
      data: {
        mandatory: true
      }
    });

    dialogRef
      .afterClosed()
      .subscribe(result => {
        if (result) {
          this.snackBar.open(result.message, result.status, {
            duration: 7000, });
        }
      });
  }


  getLoginErrorMessage() {
    return this.login.hasError("required")
      ? "Это объязательное поле!"
      : this.login.hasError("minlength")
      ? "Логин должен содержать не менее 3 символов"
      : "";
  }

  getPasswordErrorMessage() {
    return this.password.hasError("required")
      ? "Это объязательное поле!"
      : this.password.hasError("minlength")
      ? "Пароль должен содержать не менее 3 символов"
      : "";
  }

  getRegisterLoginErrorMessage() {
    return this.registerLogin.hasError("required")
      ? "Это объязательное поле!"
      : this.registerLogin.hasError("minlength")
      ? "Логин должен содержать не менее 3 символов"
      : "";
  }

  getRegisterPasswordErrorMessage() {
    return this.registerPassword.hasError("required")
      ? "Это объязательное поле!"
      : this.registerPassword.hasError("minlength")
      ? "Пароль должен содержать не менее 3 символов"
      : "";
  }

  getRegisterConfirmPasswordErrorMessage() {
    return this.confirmPassword.hasError("required")
      ? "Это объязательное поле!"
      : this.confirmPassword.hasError("minlength")
      ? "Пароль должен содержать не менее 3 символов"
      : this.confirmPassword.hasError("mustMatch")
      ? "Пароли не совпадают"
      : "";
  }

  get formControls() {
    return this.registerForm.controls;
  }

  showRegisterContent() {
    this.registerContent = true;
    this.initRegisterForm();
  }

  onReset() {
    this.registerContent = false;
    this.submitted = false;
    this.registerForm.reset();
  }

  register() {
    if (this.registerContent) {
      this.submitted = true;
      if (this.registerForm.invalid) {
        return;
      }
      this.registerForm.value.username = this.registerForm.value.email;

      this.authService.register(this.registerForm.value).subscribe(
        (res) => {
          if (res) {
            this.snackBar.open(
              "Валидация прошла успешно! Проверьте почту чтобы пройти активацию",
              "",
              { duration: 3000 }
            );
            this.onReset();
          }
        },
        (error) => {
          console.log(error);
          if (error.status === 409) {
            this.snackBar.open(
              "Пользователь с такой почтой уже зарегистрирован !",
              "",
              { duration: 3000 }
            );
          }
        }
      );
    }
  }

  reactivate() {
    if (this.login.value) {
      console.log(this.login.value);
      this.authService.reactivateUser(this.login.value).subscribe(
        (res) => {
          if (res) {
            this.snackBar.open("Проверьте почту чтобы пройти активацию", "", {
              duration: 3000,
            });
            this.message = "";
          }
        },
        (error1) => {
          this.snackBar.open("Упс! Что - то пошло не так !", "", {
            duration: 3000,
          });
        }
      );
    }
  }

  public onKeyUp(event: KeyboardEvent): boolean {
    if (event.altKey && event.key === "n") {
      this.fakeLogin();
    }
    return true;
  }

  ngOnDestroy() {
  this.destroyed$.next();
  this.destroyed$.complete();
}

}
