import {CommonModule} from '@angular/common';
import {HttpClient} from '@angular/common/http';
import {NgModule} from '@angular/core';
import {FlexLayoutModule} from '@angular/flex-layout';
import {RouterModule} from '@angular/router';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {NgxSpinnerModule} from 'ngx-spinner';
import {MaterialModule} from './modules/material.module';
import {DestroyableComponent} from './components/destroyable/destroyable.component';
import {CountdownPipe} from './countdown.pipe';
import {SafeHtmlPipe} from './pipes/safe-html.pipe';
import {DynamicTranslateShPipe} from './pipes/dynamic-translate-sh.pipe';
import {DynamicTranslatePipe} from './pipes/dynamic-translate.pipe';
import {NgxMaskModule} from 'ngx-mask';
import {SafeResourceUrlPipe} from './pipes/safe-resource-url.pipe';
import {SafeStylePipe} from './pipes/safe-style-pipe';
import {UppercaseUnderscoreDirective} from './directives/uppercase.directive';
import {MessagePopupModule} from '../components/message-popup/message-popup.module';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/locale/', '.json');
}

@NgModule({
  declarations: [
    DestroyableComponent,
    CountdownPipe,
    DynamicTranslatePipe,
    DynamicTranslateShPipe,
    SafeHtmlPipe,
    SafeResourceUrlPipe,
    SafeStylePipe,
    UppercaseUnderscoreDirective
  ],
  exports: [
    MaterialModule,
    FlexLayoutModule,
    NgxSpinnerModule,
    TranslateModule,
    DestroyableComponent,
    CountdownPipe,
    DynamicTranslatePipe,
    DynamicTranslateShPipe,
    SafeHtmlPipe,
    SafeResourceUrlPipe,
    SafeStylePipe,
    UppercaseUnderscoreDirective
  ],
  imports: [
    CommonModule,
    RouterModule,
    MaterialModule,
    FlexLayoutModule,
    NgxSpinnerModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    NgxMaskModule.forRoot(),
    MessagePopupModule
  ],
  entryComponents: [
    DestroyableComponent,
  ]
})
export class SharedModule {}
