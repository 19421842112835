import {Pipe, PipeTransform} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';

@Pipe({
    name: 'dynamicTranslateSh',
    pure: false
})
export class DynamicTranslateShPipe implements PipeTransform {
    private readonly defaultLang;

    constructor(public translate: TranslateService,
                private sanitizer: DomSanitizer) {
        this.defaultLang = this.translate.getDefaultLang();
    }

    transform(obj: any): SafeHtml {
        if (!obj) {
            return '';
        }

        const lang = this.translate.currentLang || this.defaultLang;
        const html = obj[lang === 'kk' ? 'kz' : 'ru'];
        return this.sanitizer.bypassSecurityTrustHtml(html);
    }
}
