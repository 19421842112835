import {Injectable, OnDestroy, SecurityContext} from '@angular/core';
import {Subject} from 'rxjs';
import {AuthService} from './auth.service';
import {AuthorizedParams, LANGUAGE_QUERY_PARAM, ProjectRedirectParams, TEST_ACCESS_PASS_QUERY_PARAM, UNSAFE_URL_PREFIX, WindowFeature, WindowTarget} from '../shared/models/redirect.model';
import {DomSanitizer} from '@angular/platform-browser';


import { environment } from 'src/environments/environment';
import { LocalizationService } from './localization/localization.service';
import { appendQueryParamsToUrl } from '../shared/utils/web.api.utility';
import { TOKEN_STORAGE_KEY } from '../shared/models/auth.model';

@Injectable({
  providedIn: 'root'
})
export class RedirectService implements OnDestroy {
  private readonly destroyed$: Subject<void>;
  private readonly safeFeatures: WindowFeature[];
  private readonly featuresSeparator: string;

  constructor(
    private authService: AuthService,
    private sanitizer: DomSanitizer,
    private l10nService: LocalizationService
  ) {
    this.destroyed$ = new Subject<void>();
    this.safeFeatures = [
      'noopener', 'noreferrer'
    ];
    this.featuresSeparator = ',';
  }

  public ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  public handleLink(
    url: string,
    target: WindowTarget,
    features: WindowFeature | WindowFeature[] = ''
  ): void {
    this.safeWindowOpen(url, target, features);
  }
  public appendQueryParamsToURL(
    url: string,
    queryParams?: AuthorizedParams
  ): string {
    let newURL: string = url;
    if (queryParams) {
      const paramsParts: string[] = [];
      const keys: string[] = Object.keys(queryParams);
      for (const key of keys) {
        const value: any = queryParams[key];
        if (
          key === this.authService.tokenStorageKey
          && environment.production || !value
        ) {
          continue;
        }
        if (value) {
          paramsParts.push(`${key}=${value}`);
        }
      }
      if (paramsParts.length > 0) {
        newURL += `?${paramsParts.join('&')}`;
      }
    }
    return newURL;
  }

  public appendAccessTokenAndOtherParamsToURL(
    url: string,
    queryParams?: AuthorizedParams
  ): string {
    return this.appendQueryParamsToURL(
      url,
      {
        ...queryParams,
        [this.authService.tokenStorageKey]: this.authService.tokenFromStorage
      }
    );
  }


  public redirectWithTokenParam(
    url: string,
    queryParams?: AuthorizedParams
  ): void {
    this.handleLink(
      this.appendAccessTokenAndOtherParamsToURL(url, queryParams),
      '_self'
    );
  }

  public redirectToProject(
    {
      url,
      params = {},
      target = '_self',
      auth = true,
      testAccess = false,
      localize = true
    }: ProjectRedirectParams
  ): void {
    if (auth) {
      params[TOKEN_STORAGE_KEY] = this.authService.tokenFromStorage;
    }
    if (testAccess && environment.testEnvironment.flag) {
      params[TEST_ACCESS_PASS_QUERY_PARAM] = true;
    }
    if (localize) {
      params[LANGUAGE_QUERY_PARAM] = this.l10nService.storageCurrentLanguage;
    }
    this.safeWindowOpen(
      appendQueryParamsToUrl(
        url,
        params
      ),
      target
    );
  }

  // public redirectToProject(
  //   {
  //     url,
  //     params = {},
  //     target = '_self',
  //     authToken = true,
  //     keepAuth = false,
  //     testAccess = false,
  //     localize = true
  //   }: ProjectRedirectParams
  // ): void {
  //   if (keepAuth) {
  //     this.authService.setKeepAuthenticationFlag(true);
  //   }
  //   if (authToken) {
  //     params[TOKEN_STORAGE_KEY] = this.authService.getToken();
  //   }
  //   if (testAccess && environment.testEnvironment.flag) {
  //     params[TEST_ACCESS_PASS_QUERY_PARAM] = true;
  //   }
  //   if (localize) {
  //     params[LANGUAGE_QUERY_PARAM] = this.l10nService.storageCurrentLanguage;
  //   }
  //   this.safeWindowOpen(
  //     appendQueryParamsToUrl(
  //       url,
  //       params
  //     ),
  //     target
  //   );
  // }


  private safeWindowOpen(
    url: string,
    target: WindowTarget,
    features: WindowFeature | WindowFeature[] = ''
  ): void {
    const sanitizedUrl: string = this.sanitizer.sanitize(SecurityContext.URL, url);
    if (sanitizedUrl && !sanitizedUrl.includes(UNSAFE_URL_PREFIX)) {
      const _window: Window = window.open(
        encodeURI(url),
        target,
        this.appendSafeFeatures(features)
      );
      if (_window) {
        _window.opener = null;
      }
    }
  }

  private appendSafeFeatures(features: WindowFeature | WindowFeature[]): string {
    if (features) {
      let newFeatures: WindowFeature[];
      if (Array.isArray(features)) {
        newFeatures = features;
      } else {
        newFeatures = features
          .replace(/\s+/g, '')
          .split(this.featuresSeparator);
      }
      const safeFeaturesPresent: boolean = this.safeFeatures
        .every((feature: string) => newFeatures.includes(feature));
      newFeatures
        = safeFeaturesPresent
        ? newFeatures
        : [...newFeatures, ...this.safeFeatures];
      return newFeatures.join(this.featuresSeparator);
    } else {
      return this.safeFeatures.join(this.featuresSeparator);
    }
  }
}
