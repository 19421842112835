import {Component, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  constructor(
    private translate: TranslateService
  ) {
    if (localStorage.getItem('currentLang') === 'kk') {
      translate.setDefaultLang('kk');
    } else {
      translate.setDefaultLang('ru');
    }
  }
  title = 'eatyrau-web';
}
