import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

import {Nullable, NullableUndefined} from '../../shared/models/types.model';
import {take} from 'rxjs/operators';

import { CURRENT_LANGUAGE_KEY, DEFAULT_LANGUAGE, Language, LanguageLike, LanguageMap } from 'src/app/shared/models/translation.model';
import { castToLanguage, isLanguage } from 'src/app/shared/utils/translation.utility';

@Injectable({
  providedIn: 'root'
})
export class LocalizationService {
  constructor(
    private translateService: TranslateService
  ) {}

  public get storageCurrentLanguage(): Nullable<Language> {
    const value: Nullable<string> = window
      .localStorage
      .getItem(CURRENT_LANGUAGE_KEY);
    return value ? castToLanguage(value) : null;
  }

  public setDefaultLanguage(
    language: Language = DEFAULT_LANGUAGE
  ): void {
    this.translateService.setDefaultLang(language);
  }

  public setCurrentLanguage(
    language: NullableUndefined<LanguageLike>,
    languageMap?: LanguageMap<string, Language>
  ): void {
    const value: NullableUndefined<LanguageLike> =
      languageMap
        ? languageMap[language]
        : language;
    this.useLanguage(
      isLanguage(value)
        ? this.setStorageCurrentLanguage(value)
        : this.storageCurrentLanguage || DEFAULT_LANGUAGE
    );
  }

  private setStorageCurrentLanguage(
    language: NullableUndefined<LanguageLike>
  ): Language {
    const value: Language = castToLanguage(language);
    window
      .localStorage
      .setItem(
        CURRENT_LANGUAGE_KEY,
        value
      );
    return value;
  }

  private useLanguage(language: Language): void {
    this.translateService
      .use(language)
      .pipe(
        take(1)
      )
      .subscribe();
  }
}
