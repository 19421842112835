import {Params} from '@angular/router';
import {Nullable, NullableUndefined} from '../models/types.model';

export function getQueryParams(
  url: NullableUndefined<string>,
  strictParams: boolean = true
): Nullable<Params> {
  if (url) {
    try {
      const sourceUrl: URL = new URL(url);
      const params: Params = {};
      const sourceParams: string = sourceUrl.hash || sourceUrl.search;
      if (sourceParams) {
        const index: number = sourceParams.indexOf('?');
        if (index > -1) {
          const parts: string[] = sourceParams
            .slice(index + 1)
            .split('&');
          if (parts.length > 0) {
            for (const part of parts) {
              const keyValue: string[] = part.split('=');
              if (keyValue.length === 2) {
                params[keyValue[0]] = keyValue[1];
              } else if (!strictParams) {
                params[keyValue[0]] = true;
              }
            }
          }
        }
      }
      return params;
    } catch {}
  }
  return null;
}

export function getQueryParam(
  url: NullableUndefined<string>,
  key: string,
  strict: boolean = false
): Nullable<string> {
  const params: Nullable<Params> = getQueryParams(url, strict);
  if (params) {
    return params[key] || null;
  }
  return null;
}

export function appendQueryParamsToUrl(
  url: NullableUndefined<string>,
  params: Params = {},
  strict: boolean = false
): string {
  if (url) {
    const keys: string[] = Object
      .keys(params)
      .filter((key: string) => !strict || !!params[key]);
    if (keys.length > 0) {
      for (let i = 0; i < keys.length; ++i) {
        const key: string = keys[i];
        url = appendQueryParamToUrl(url, key, params[key], strict, i === 0);
      }
    }
    return url;
  }
  return '';
}

export function appendQueryParamToUrl(
  url: NullableUndefined<string>,
  paramKey: string,
  paramValue: any,
  strict: boolean = false,
  first: boolean = true
): string {
  if (url && (!strict || !!paramValue)) {
    let param: string | undefined;
    switch (typeof paramValue) {
      case 'number':
      case 'string':
      case 'boolean':
        param = `${paramKey}=${encodeURIComponent(paramValue)}`;
        break;
      case 'object':
        if (paramValue) {
          let json: any;
          try {
            json = JSON.stringify(paramValue);
            if (!strict || !!json) {
              param = `${paramKey}=${encodeURIComponent(json)}`;
            }
          } catch {}
        }
        break;
    }
    if (param) {
      url += first ? '?' : '&';
      url += param;
    }
    return url;
  }
  return '';
}
