import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import * as L from 'leaflet';

import {ConnectedLayerService} from './connected-layer.service';
import {LayersService} from './layers.service';
import {ApiService} from './api.service';
import {MapControlsService} from './map.controls.service';
import {MapService} from './map.service';
import {BehaviorSubject, forkJoin, Observable, of, Subject} from 'rxjs';
import {Feature, FeatureCollection} from 'geojson';
import {first, takeUntil} from 'rxjs/operators';

import {LayerAttribute} from '../shared/models/layer/layer-attribute';
import {Layer} from '../shared/models/layer/layer';
import {LayerGroup} from '../shared/models/layer/layer-group.model';
import {CONST} from '../config';
import CONFIG = CONST.CONFIG;
import {Geometry} from 'ngx-perfect-scrollbar';
import {CoordinateService} from './coordinate.service';
import {MapDrawControlService} from './map-draw-control.service';
import {GeomService} from './geom.service';

export abstract class GEOMETRYTYPES {
  public static readonly POINT = 'POINT';
  public static readonly MULTIPOINT = 'MULTIPOINT';
  public static readonly LINESTRING = 'LINESTRING';
  public static readonly MULTILINESTRING = 'MULTILINESTRING';
  public static readonly POLYGON = 'POLYGON';
  public static readonly MULTIPOLYGON = 'MULTIPOLYGON';
  public static readonly RASTER = 'RASTER';
}

@Injectable()
export class IdentifyService {
  layerName: string;
  private layerInfoByFeature: Layer = null;
  private layerAttributes: LayerAttribute[] = [];
  private identifyLayers: LayerGroup[] = [];
  private showCardSubject$: BehaviorSubject<boolean>;
  private canIdentifyLayers$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public showCard: Observable<boolean>;
  public editableLayers: L.FeatureGroup = new L.FeatureGroup();

  private mapDrawControlService: MapDrawControlService;
  private coordinateService: CoordinateService;
  map: L.Map;
  private destroyed$ = new Subject();
  selectedObj;
  preparedWktString = new Subject<string>();


  constructor(
    private http: HttpClient,
    private lyrSvc: LayersService,
    private api: ApiService,
    private conLyrSvc: ConnectedLayerService,
    private mapControlsSvc: MapControlsService,
    private mapSvc: MapService,
    private geomSvc: GeomService
  ) {
    this.showCardSubject$ = new BehaviorSubject<boolean>(false);
    this.showCard = this.showCardSubject$.asObservable();
    setTimeout(() => {
      this.initMap();
    }, 1000);
  }

  showGetFeatureInfo(map: L.Map, latlng) {
    this.conLyrSvc.getConnectedLayers();
    const path = './assets/images/spinner.gif';
    const popup = L.popup()
      .setLatLng(latlng)
      // .setContent('<br />')
      .setContent(`<img src="${path}" alt="preloader" style="height: 16px; width: 16px; padding-left: 16px;">`)
      .openOn(map);
    // const LatLng = L.Projection.SphericalMercator.project(latlng);
    const resp = this.getFeatureInfo(map, latlng);
    resp.subscribe(
      (data: any) => {
        if (data.features && data.features.length > 0) {
          this.layerName = data.features['0'].id.split('.');
          this.http.post('/eqyzmet/api/engineering/get/layers', {
            layerName: this.layerName[0]
          }).subscribe(res => {
            if (res) {
              this.showPopup(data, popup, res);
            }
          }, () => {
            this.showPopup(data, popup);
          });
        } else {
          popup.setContent('<p>Ничего не найдено</p>');
        }
      },
      error => {
        console.log('error: ', error);
        popup.setContent('<p>Ничего не найдено</p>');
      }
    );
    // console.log('resp: ', resp);
  }

  getFeatureInfo(map: L.Map, latlng: any, burl?: string) {
    // const baseUrl = CONFIG.GEOSERVER_URL + `utilities/wms?`;
    const baseUrl = burl ? burl : '/geoserver/utilities/wms?';
    const url = this.getFeatureInfoUrl(baseUrl, map, latlng);
    return this.http.get(url);
  }

  private getFeatureInfoUrl(baseurl: string, map: L.Map, latlng: L.LatLng) {
    const point = map.latLngToContainerPoint(latlng),
      size = map.getSize(),
      params = {
        request: 'GetFeatureInfo',
        service: 'WMS',
        srs: 'EPSG:3857',
        // styles: '',
        transparent: true,
        version: '1.1.1',
        format: 'image/png',
        // bbox: map.getBounds().toBBoxString(),
        bbox: this.getMercatorBBoxString(map),
        height: size.y,
        width: size.x,
        layers: this.lyrSvc.identifyLayersLst, // ['utilities:heat_teplovie_punkti_a'],
        query_layers: this.lyrSvc.identifyLayersLst, // ['utilities:heat_teplovie_punkti_a'],
        info_format: 'application/json',
        // info_format: 'text/html'
      };

    params[params.version === '1.3.0' ? 'i' : 'x'] = Math.round(point.x);
    params[params.version === '1.3.0' ? 'j' : 'y'] = Math.round(point.y);

    return baseurl + L.Util.getParamString(params, baseurl, true);
  }

  private getFeatureInfoUrlAtribute(baseUrl: string, latlng: L.LatLng, layers: string, epsg: string = 'EPSG:4326') {
    const map = this.mapSvc.getMap();
    const point = map.latLngToContainerPoint(latlng);
    const size = map.getSize();
    const params = {
      request: 'GetFeatureInfo',
      service: 'WMS',
      srs: epsg,
      // styles: '',
      transparent: true,
      version: '1.1.1',
      format: 'image/png',
      // bbox: map.getBounds().toBBoxString(),
      bbox: this.getMercatorBBoxString(map),
      height: size.y,
      width: size.x,
      layers,
      query_layers: layers,
      feature_count: 100,
      info_format: 'application/json',
      // info_format: 'text/html'
    };

    params[params.version === '1.3.0' ? 'i' : 'x'] = Math.round(point.x);
    params[params.version === '1.3.0' ? 'j' : 'y'] = Math.round(point.y);

    return baseUrl + L.Util.getParamString(params, baseUrl, true);
  }

  getMercatorBBoxString(map: L.Map) {
    const bounds = map.getBounds();
    const west = L.Projection.SphericalMercator.project(bounds.getNorthWest()).x;
    const south = L.Projection.SphericalMercator.project(bounds.getSouthWest()).y;
    const east = L.Projection.SphericalMercator.project(bounds.getNorthEast()).x;
    const north = L.Projection.SphericalMercator.project(bounds.getNorthWest()).y;
    return west + ',' + south + ',' + east + ',' + north + '';
  }

  buildFeatureInfoContent(data: any, popup: any) {
    const props = data.features['0'].properties;
    let content = '';
    // let content = '<p>' + props.layername + '<br />';
    for (const item in props) {
      if (props.hasOwnProperty(item)) {
        // console.log(item + ': ' + props[item]);
        const propValue = props[item];
        if (propValue && item !== 'shape_area' && item !== 'shape_leng') {
          content += '<p><strong>' + item + '</strong>: ' + propValue + '<br />';
        }
        popup.setContent(content);
      }
    }
  }

  // end
  showPopup(data: any, popup: any, attrs?: any) {
    const props = data.features['0'].properties;
    let content = '';
    for (const item in props) {
      if (props.hasOwnProperty(item)) {
        let attr;
        if (attrs !== undefined && attrs) {
          attr = attrs.filter(a => a.nameEn === item);
        }
        if (attrs !== undefined && attr.length > 0) {
          attr = attrs ? attr[0].nameRu : item;
        } else {
          attr = item;
        }
        const propValue = props[item];
        if (propValue && item !== 'shape_area' && item !== 'shape_leng') {
          content += '<p><strong>' + attr + '</strong>: ' + propValue + '<br />';
        }
      }
    }
    popup.setContent(content);
  }

  public async showFeatureInfo(latlng: L.LatLng) {
    const geoportalUrls = this.fetchGeoportalUrlsFromLayers(this.mapControlsSvc.selectedLayer);
    if (!geoportalUrls.length) {
      this.setPopupContent(latlng, '<p>Произошла ошибка! Не найден геосервер!</p>');
      return;
    }
    const requests = geoportalUrls.map(url => {
      return this.getFeatureInfoDutyMap(url, latlng);
    });
    const obs$: Observable<any[]> = forkJoin(requests);
    obs$.subscribe((reqs: Observable<any>[]) => {
      reqs.forEach((req, index) => {
        req.subscribe((f: FeatureCollection) => {
          if (f && f.features && f.features.length) {
            const fLayerData = this.getLayerDataFromFeatures(f.features[0]);
            const fLayerName = fLayerData[0];
            const layerGroup = this.mapControlsSvc.selectedLayer.find(l => l.layerGroupName === fLayerName);
            if (layerGroup && layerGroup.geoserver && layerGroup.geoserver.id) {
              this.showFeatureInfoDutyMap(latlng, f.features[0], layerGroup);
              return;
            }
          } else if (index === reqs.length - 1) {
            this.setPopupContent(latlng, '<p>Нет данных</p>');
          }
        });
      });
    });
  }

  private getLayerDataFromFeatures(feature: Feature) {
    if (!feature) {
      return null;
    }
    const featureId: any = feature.id ? feature.id : null;
    return featureId ? featureId.split('.') : null;
  }

  public fetchGeoportalUrlsFromLayers(layers: any []) {
    const arr = [];
    layers.map(l => {
      if (l.geoserver && l.geoserver.urlContext) {
        arr.push(l.geoserver.urlContext);
      } else if (l.layerSource && l.layerSource.url) {
        arr.push(l.layerSource.url);
      }
    });
    return [...new Set(arr)];
  }

  public async getFeatureInfoDutyMap(baseUrl: string, latlng: L.LatLng) {
    const layers = this.fetchLayersByGeoportalUrl(this.mapControlsSvc.selectedLayer, baseUrl).join(',');
    const group = baseUrl.split('/');
    const layerQuery = layers.length ? group[group.length - 1] + ':' + layers : '';
    const url = this.getFeatureInfoUrlDutyMap(baseUrl + '/wms?', latlng, layerQuery, 'EPSG:3857');
    return this.http.get(url);
  }

  public fetchLayersByGeoportalUrl(layers: any[], url: string) {
    const arr = [];
    layers.map(l => {
      if (l.geoserver && l.geoserver.urlContext === url) {
        arr.push(l.layerGroupName);
      } else if (l.layerSource && l.layerSource.url === url) {
        arr.push(l.layerName);
      }
    });
    return arr;
  }

  private getFeatureInfoUrlDutyMap(baseUrl: string, latlng: L.LatLng, layers: string, epsg: string = 'EPSG:4326') {
    const map = this.mapSvc.map;
    const point = map.latLngToContainerPoint(latlng);
    const size = map.getSize();
    const params = {
      request: 'GetFeatureInfo',
      service: 'WMS',
      srs: epsg,
      // styles: '',
      transparent: true,
      version: '1.1.1',
      format: 'image/png',
      // bbox: map.getBounds().toBBoxString(),
      bbox: this.getMercatorBBoxString(map),
      height: size.y,
      width: size.x,
      layers: layers, // ['utilities:heat_teplovie_punkti_a'],
      query_layers: layers, // ['utilities:heat_teplovie_punkti_a'],
      info_format: 'application/json',
      // info_format: 'text/html'
    };

    params[params.version === '1.3.0' ? 'i' : 'x'] = Math.round(point.x);
    params[params.version === '1.3.0' ? 'j' : 'y'] = Math.round(point.y);

    return baseUrl + L.Util.getParamString(params, baseUrl, true);
  }

  public async showFeatureInfoDutyMap(latlng: L.LatLng, feature: Feature, layerGroup: LayerGroup) {
    const layerObjectId = +this.getLayerDataFromFeatures(feature)[1];
    await this.fetchLayerInfo(layerGroup.layerGroupName, layerGroup.geoserver.id);
    // await this.fetchLayerObject(layerGroup.layerGroupName, layerObjectId, layerGroup.geoserver.id);
    this.showPopupDutyMap(feature, latlng, layerGroup.layerGroupName, layerGroup.geoserver.id);
  }

  private async fetchLayerInfo(layerName: string, geoserverId: number) {
    await this.lyrSvc.getLayerByLayerName(layerName, geoserverId).toPromise()
      .then((data: Layer) => {
        this.layerInfoByFeature = data ? data : null;
      })
      .catch(() => this.layerInfoByFeature = null);
  }

  private async fetchLayerObject(layerName: string, layerObjectId: number, geoserverId: number) {
    await this.lyrSvc.getLayerObjectFromService(layerName, layerObjectId, geoserverId).toPromise()
      .then((data: Layer) => {
        this.lyrSvc.setLayerObject(data ? data : null);
      })
      .catch(() => this.lyrSvc.setLayerObject(null));
  }

  private async showPopupDutyMap(feature: Feature, latlng: L.LatLng, layerName: string, geoserverId: number) {
    await this.fetchLayerAttributes(layerName, geoserverId);
    const props = feature.properties;
    if (this.layerAttributes && this.layerAttributes.length) {
      const headerContent = this.fetchPopupHeaderContentFromFeature(feature);
      const content = headerContent + this.fetchLayerProps(props, this.layerAttributes);
      this.setPopupContent(latlng, content ? content : '<p>Нет данных</p>');
    } else {
      const headerContent = this.fetchPopupHeaderContentFromFeature(feature);
      this.setPopupContent(latlng, headerContent ? headerContent : '<p>Нет данных</p>');
    }
  }

  private async fetchLayerAttributes(layerName: string, geoserverId: number) {
    await this.lyrSvc.getAttributesByLayerName(layerName, geoserverId).toPromise()
      .then((attrs: LayerAttribute[]) => {
        this.layerAttributes = attrs && attrs.length ? this.sortByOrder(attrs) : [];
      })
      .catch(() => this.layerAttributes = []);
  }

  public sortByOrder(arr: any[]) {
    return arr.sort((a, b) => a.orderId - b.orderId);
  }

  private fetchPopupHeaderContentFromFeature(feature: Feature) {
    const featureId: any = feature.id ? feature.id : null;
    if (!featureId) {
      return '';
    }
    const featureIdContent = featureId.split('.');

    let layerNameAttr: string;
    if (this.layerInfoByFeature && this.layerInfoByFeature.nameRu) {
      layerNameAttr = `<p><strong>${this.layerInfoByFeature.nameRu}‎</strong><br />`;
    } else {
      layerNameAttr = featureIdContent[0] ? `<p><strong>${featureIdContent[0]}‎</strong><br />` : '';
    }
    const id = featureIdContent[1] ? `<p><strong>gid: ${featureIdContent[1]}‎</strong><br />` : '';
    return layerNameAttr + id;
  }

  private fetchLayerProps(props: object, attrs: LayerAttribute[]) {
    let content = '';
    attrs.forEach((attr: LayerAttribute) => {
      const key = attr.attrName;
      if (props.hasOwnProperty(key)) {
        const propValue = props[key] ? props[key] : '';
        if (key !== 'shape_area' && key !== 'shape_leng') {
          content += '<tr>' + `<td>${attr.nameRu}</td>` + `<td>${propValue}</td>` + '</tr>';
        }
      }
    });
    return content ? `<div class="table-container">
                            <table class="table table-bordered">${content}</table>
                          </div>` : content;
  }

  private setPopupContent(latlng: L.LatLng, content: string) {
    const popup = L.popup().setLatLng(latlng)
      .setContent(`<div class="leaflet-custom-popup-content">${content}</div>`)
      .openOn(this.mapSvc.map);
  }

  public getIdentifyLayers() {
    return this.identifyLayers;
  }

  public addIdentifyLayer(layerGroup: LayerGroup | any) {
    this.identifyLayers.push(layerGroup);
  }

  public removeIdentifyLayer(index: number) {
    this.identifyLayers.splice(index, 1);
  }

  public clearIdentifyLayer() {
    this.identifyLayers.splice(0);
  }

  public async showGetFeatureInfoAtribut(latlng: L.LatLng) {
    const geoportalUrls = this.fetchGeoportalUrlsFromLayers(this.mapControlsSvc.selectedLayer);
    if (!geoportalUrls.length) {
      this.setShowingCardValue(false);
      this.setPopupContent(latlng, '<p>Произошла ошибка! Не найден геосервер!</p>');
      return;
    }
    for (let index = 0; index < geoportalUrls.length; index++) {
      const url = geoportalUrls[index];
      try {
        const f: FeatureCollection = await this.getFeatureInfoAtribute(url, latlng)
          .pipe(first())
          .toPromise();

        if (f && f.features && f.features.length) {
          const featIdx = f.features.length - 1;
          let fLayerName: string = f.features[0].id as string;
          fLayerName = fLayerName.substring(0, 5).toUpperCase();
          const layerGroup = this.mapControlsSvc.selectedLayer.find(l => l.layerGroupName.substring(0, 5).toUpperCase() === fLayerName);
          if (layerGroup && layerGroup.geoserver && layerGroup.geoserver.id) {
            this.editableLayers.clearLayers();
            this.mapDrawControlService.destroyDraw(this.map);
            await this.showFeatureInfoAtribut(latlng, f.features[featIdx], layerGroup.geoserver.id);
            this.drawGeometryOnMap(f.features[featIdx].geometry, true);
            return;
          }
          return;
        } else if (index === geoportalUrls.length - 1) {
          this.setShowingCardValue(false);
          this.setPopupContent(latlng, '<p>Объект не определен!</p>');
        }
      } catch (error) {
        this.setShowingCardValue(false);
        this.setPopupContent(latlng, '<p>Нет данных</p>');
      }
    }
  }


  public getFeatureInfoAtribute(baseUrl: string, latlng: L.LatLng): Observable<any> {
    const layers = this.fetchLayersByGeoportalUrlAtribute(this.getIdentifyLayers(), baseUrl).join(',');
    const group = baseUrl.split('/');
    const layerQuery = layers.length ? group[group.length - 1] + ':' + layers : '';
    const url = this.getFeatureInfoUrlAtribute(baseUrl + '/wms?', latlng, layerQuery, 'EPSG:3857');
    return this.http.get(url);
  }


  public setShowingCardValue(value: boolean) {
    this.showCardSubject$.next(value);
  }

  public async showFeatureInfoAtribut(latlng: L.LatLng, feature: Feature, geoserverId: number) {
    const fLayerData = this.getLayerDataFromFeatures(feature);
    const fLayerName = fLayerData[0];
    const fLayerObjectId = fLayerData[1];
    await this.fetchLayerInfo(fLayerName, geoserverId);
    await this.fetchLayerObjectAtribute(fLayerName, fLayerObjectId, geoserverId, latlng);
    this.setShowingCardValue(true);
    this.showPopupAtribute(feature, latlng, fLayerName, geoserverId);
    if (feature) {
      this.layerInfoByFeature.feature = feature;
    }
    this.lyrSvc.setLayer(this.layerInfoByFeature);
    if (this.getCanIdentifyValue()) {
      this.showPopupAtribute(feature, latlng, fLayerName, geoserverId);
    }
  }

  public getCanIdentifyValue(): boolean {
    return this.canIdentifyLayers$.value;
  }

  private async showPopupAtribute(feature: Feature, latlng: L.LatLng, layerName: string, geoserverId: number) {
    await this.fetchLayerAttributes(layerName, geoserverId);
    const props = feature.properties;
    if (this.layerAttributes && this.layerAttributes.length) {
      const headerContent = this.fetchPopupHeaderContentFromFeature(feature);
      const content = headerContent + this.fetchLayerProps(props, this.layerAttributes);
      this.setPopupContent(latlng, content ? content : '<p>Нет данных</p>');
    } else {
      const headerContent = this.fetchPopupHeaderContentFromFeature(feature);
      this.setPopupContent(latlng, headerContent ? headerContent : '<p>Нет данных</p>');
    }
  }

  private async fetchLayerObjectAtribute(layerName: string, layerObjectId: number, geoserverId: number, latlng: L.LatLng,) {
    const map = this.mapSvc.getMap();
    const geom = this.getMercatorBBoxString(map);
    const size = map.getSize();
    const height = size.y;
    const width = size.x;
    const point = map.latLngToContainerPoint(latlng);
    const x = Math.round(point.x);
    const y = Math.round(point.y);

    await this.getLayerObject(layerName, layerObjectId, geoserverId, geom, height, width, x, y).toPromise()
      .then((data: Layer) => {
        this.lyrSvc.setLayerObject(data ? data : null);
      })
      .catch((error) => this.lyrSvc.setLayerObject(null));
  }

  public getLayerObject(layerName: string, objectId: number, geoserverId: number, geom?: string, height?: any, width?: any, x?: any, y?: any) {
    if (layerName && objectId) {
      const url = `layers/${layerName}/objects/${objectId}?geoserverId=${geoserverId}`;
      const params = {
        bbox: geom,
        height: height,
        width: width,
        x: x,
        y: y
      }
      return this.api.get5(url, params);
    }
    return null;
  }

  public fetchLayersByGeoportalUrlAtribute(layers: any[], url: string) {
    const arr = [];
    layers.map(l => {
      arr.push(l.layer.options.layers);
    });
    return arr;
  }




/*
    Ниже все функций от WorkingLayersService DutyMAP
    ↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓
*/
  drawGeometryOnMap(geometry: any, withMeasurements = false) {
      geometry.coordinates.map(coordinates => {
        const coords = this.parseCoords(coordinates[0]);
        const layer: any = this.fetchLayerOfGeometry(
          geometry.type,
          coords
        );
        if (!layer) {
          return;
        }
        this.editableLayers.addLayer(layer);
        if (!this.map) {
          this.initMap();
        }
        if (withMeasurements) {
          setTimeout(() => {
            this.showMeasurements(layer);
          }, 100);
          return;
        }
        this.map.fitBounds(layer.getBounds());
      });
  }

  showMeasurements(lyr: any): void {
    if (!lyr) {
      return;
    }
    setTimeout(() => {
      this.showMeasurements(lyr);
    }, 100);
  }

  initMap() {
    this.map = this.mapSvc.getMap();
    if (!this.map) {
      return;
    }
    this.map.addLayer(this.editableLayers);
    // this.mapDrawControlService.drawSubject.pipe(takeUntil(this.destroyed$)).subscribe((val: any) => {
    //   if (val.drawEvent === 'drawend') {
    //     this.clearEditableLayers(false);
    //     this.selectedObj = val.layer;
    //     this.editableLayers.addLayer(val.layer);
    //     this.prepareWktGeom(val.type);
    //   }
    // });
  }

  public fetchLayerOfGeometry(geometryType: string, coords: any[]) {
    const geomType = geometryType.toUpperCase();
    switch (geomType) {
      case GEOMETRYTYPES.POLYGON:
      case GEOMETRYTYPES.MULTIPOLYGON:
        return L.polygon(coords);
      case GEOMETRYTYPES.LINESTRING:
      case GEOMETRYTYPES.MULTILINESTRING:
        return L.polyline(coords);
      case GEOMETRYTYPES.POINT:
        return L.point(coords[0], coords[1]);
      case GEOMETRYTYPES.MULTIPOINT:
        const greenIcon = L.icon({
          iconUrl: '../../../../assets/images/markers/default/green.png',
          iconSize: [35, 43],
        });
        return L.marker([coords[0], coords[1]], {icon: greenIcon});
      default:
        return null;
    }
  }


  getCustomMarker() {
    const CustomMarker = L.Icon.extend({
      options: {
        iconUrl: '../../../../assets/images/markers/default/marker-icon.png',
        iconRetinaUrl: '../../../../assets/images/markers/default/red.png',
        shadowUrl: '../../../../assets/images/markers/default/marker-shadow.png',
        iconSize: [38, 50],
        // iconAnchor: [19, 24],
        popupAnchor: [1, -34],
        tooltipAnchor: [16, -32],
        shadowSize: [41, 41]
      }
    });
    return CustomMarker;
  }

  private parseCoords(coordinates: any[]) {
    let coords = [...coordinates];
    // coords = this.geomSvc.convertCoordsProjectionEPSG(coords);
    coords = this.unprojCoordsArray(coords);
    if (typeof coords === 'object') {
      return coords.map((e) => this.swapLatAndLng(e));
    }
    return this.swapLatAndLng([coords[0], coords[1]]);
    // return [coords[0], coords[1]];
  }

  public unprojCoordsArray(coordinates: any[]) {
    const coords = coordinates.length === 1 ? coordinates[0] : coordinates;
    coords.forEach((item) => {
      const pointLatLng = L.Projection.SphericalMercator.unproject(L.point(item[0], item[1]));
      item[0] = pointLatLng.lng;
      item[1] = pointLatLng.lat;
    });
    return coords;
  }

  public swapLatAndLng(latlngs: number[]) {
    return latlngs.reverse();
  }

  clearEditableLayers(isClear: boolean = true) {
    if (!isClear) {
      return;
    }

    if (this.editableLayers && this.editableLayers.getLayers().length > 0) {
      this.editableLayers.clearLayers();
      this.mapSvc.clearEditableLayers();
    }
  }

  prepareWktGeom(lyrType) {
    if (this.editableLayers.getLayers().length > 0) {
      const layer = this.editableLayers.getLayers()[0];
      // const wkt = this.geomSvc.toWKT(layer, 'MULTIPOLYGON', true);
      const wkt = this.geomSvc.toWKTCoordinat(layer, lyrType, true);
      this.preparedWktString.next(wkt);
    }
  }
/*
    Конец функций от WorkingLayersService DutyMAP
 */


}
