import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ApiService} from '../services/api.service';

@Injectable()
export class DicApplicationService {

  constructor(private http: HttpClient,
              private api: ApiService) {
  }


  public getPhasesReq() {
    return this.api.get('dict/phases');
  }

  public getPhasesPeriodsReq() {
    return this.api.get('dict/phase-periods');
  }

  public getServiceReq() {
    return this.api.get('dict/services', {}, false);
  }

  public getAllSubServiceReq() {
    return this.api.get2('users/subservices', {});
  }

  public getSubservicesByServiceId(serviceId) {
    return this.api.get('dict/subservices?serviceId=' + serviceId, {}, false);
  }

  public getAppFileCategories(subserviceId) {
    return this.api.get('dict/file-types?subserviceId=' + subserviceId);
  }

  public changeAppFileCategories(appFileCategories) {
    appFileCategories.map((obj) => {
      obj.categoryFiles = [];
      obj.categoryFilesUpload = [];
      return obj;
    });
    return appFileCategories;
  }

  public getDisctrictsReq() {
    return this.api.get('dict/districts');
  }

  public getRegionsReq() {
    return this.api.get('dict/regions', {}, false);
  }

  public getRegionById(regions, regionId) {
    if (regionId && regions.length > 0) {
      const region = regions.find((reg) => reg.id === regionId);
      if (region) {
        return `(${region.nameRu})`;
      }
    }
  }


}
