import {NgModule} from '@angular/core';
import {NavigationEnd, Router, RouterModule, Routes} from '@angular/router';

import {MainComponent} from './pages/main/main.component';
import {SidenavComponent} from "./pages/sidenav/sidenav.component";
import {RedirectComponent} from "./core/auth/redirect/redirect.component";

const appRoutes: Routes = [
  {
    path: '',
    component: SidenavComponent,
    children: [
      {
        path: 'arch',
        loadChildren: './departments/architecture/architecture.module#ArchitectureModule'
      },
      {
        path: 'ozo',
        loadChildren: './departments/ozo/ozo.module#OzoModule'
      },
      {
        path: 'own',
        loadChildren: './departments/ownership/ownership.module#OwnershipModule'
      },
      {
        path: 'ijs',
        loadChildren: './departments/ijs/ijs.module#IjsModule'
      },
      {
        path: 'topographic',
        loadChildren: './departments/topographic/topographic.module#TopographicModule'
      },
      {
        path: 'sketch',
        loadChildren: './departments/sketch/sketch.module#SketchModule'
      },
      {
        path: 'apz',
        loadChildren: './departments/apz/apz.module#ApzModule'
      },
      {
        path: 'auction',
        loadChildren: './departments/auction/auction.module#AuctionModule'
      },
      {
        path: 'sales-egov-auction',
        // loadChildren: './service-departments/sales-egov-auction/sales-egov-auction.module#SalesEgovAuctionModule'
        loadChildren:()=>import('./core/ner/egov-wrapper.module').then(m=>m.EgovWrapperModule)
      },
      {
        path: 'sales-egov-beztorg',
        // loadChildren: './service-departments/sales-egov-beztorg/sales-egov-beztorg.module#SalesEgovBeztorgModule'
        loadChildren:()=>import('./core/ner/direct-wrapper.module').then(m=>m.DirectWrapperModule)
      },
      {
        path: 'specialist-decree',
        // loadChildren: './service-departments/sales-egov-beztorg/sales-egov-beztorg.module#SalesEgovBeztorgModule'
        loadChildren:()=>import('./core/ner/decree-wrapper.module').then(m=>m.DecreeWrapperModule)
      },
      {
        path: 'sales-egov-comunals',
        // loadChildren: './service-departments/sales-egov-comunals/sales-egov-comunals.module#SalesEgovComunalsModule'
        loadChildren:()=>import('./core/ner/organization-wrapper.module').then(m=>m.OrganizationWrapperModule)
      },
      {
        path: 'admin',
        loadChildren: './departments/admin/admin.module#AdminModule'
      },
      {
        path: 'directory',
        loadChildren: './departments/directory/directory.module#DirectoryModule'
      },
      {
        path: '',
        component: MainComponent
      },
      {
        path: 'df',
        loadChildren: () => import('./core/ner/dyn-wrapper.module').then(md => md.DynWrapperModule)
        // loadChildren: () => import('./modules/dynamic-form/dynamic-form.module').then(md => md.DynamicFormModule)
      },
      {
        path: 'auth',
        loadChildren: './core/auth/auth.module#AuthModule'
      },
    ]
  },
  {
    path: 'create-app',
    loadChildren: () => import('./core/ner/build-wrapper.module').then(md => md.BuildWrapperModule)
    // loadChildren: './departments/build-application/build-application.module#BuildApplicationModule'
  },
  {
    path: 'dynamic',
    loadChildren: () => import('./core/ner/dyn-wrapper.module').then(md => md.DynWrapperModule)
    // loadChildren: () => import('./modules/dynamic-form/dynamic-form.module').then(md => md.DynamicFormModule)
  },
  {
    path: 'redirect', component: RedirectComponent
  },
  {
    path: '**',
    redirectTo: ''
  }
  ];


@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes, {
      useHash: true,
      enableTracing: false,
      initialNavigation: 'enabled'
    })
  ],
  exports: [RouterModule],
  providers: []
})
export class RoutingModule {
  constructor(
    router: Router
  ) {
    // Scroll to top
    router.events.subscribe(evt => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
    });
  }
}
