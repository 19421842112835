import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MessagePopupComponent} from './message-popup.component';
import {FlexLayoutModule} from '@angular/flex-layout';
import {MaterialModule} from '../../shared/modules/material.module';
import {TranslateModule} from '@ngx-translate/core';

@NgModule({
  declarations: [MessagePopupComponent],
  imports: [
    CommonModule,
    FlexLayoutModule,
    MaterialModule,
    TranslateModule
  ],
  entryComponents: [MessagePopupComponent]
})
export class MessagePopupModule {}
