import {NullableUndefined} from '../models/types.model';
import {DEFAULT_LANGUAGE, Language, LanguageLike, LANGUAGES, TranslationWrapper} from '../models/translation.model';

export function castToLanguage(
  value: NullableUndefined<LanguageLike>
): Language {
  if (value) {
    return isLanguage(value) ? value : DEFAULT_LANGUAGE;
  }
  return DEFAULT_LANGUAGE;
}

export function isLanguage(value: any): value is Language {
  return LANGUAGES.includes(value);
}

export function castWrapperValueToString(wrapper: TranslationWrapper, key: string): string {
  const value: string | TranslationWrapper = wrapper[key];
  return typeof value === 'string' ? value : '';
}
