import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MatSnackBar, MAT_DIALOG_DATA } from '@angular/material';
import { AuthService } from '../../../services/auth.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {

  username = '';
  oldPassword = '';
  confirmPassword = '';
  newPassword = '';

  constructor(
    public dialogRef: MatDialogRef<ResetPasswordComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private http: HttpClient,
    private authService: AuthService,
    private snackBar: MatSnackBar
  ) {
    dialogRef.disableClose = true;
  }

  ngOnInit() {
    // this.authService.init();
  }

  passMessage(message, status: string): void {
    let formdata;
    if (message) {
      formdata = {
        message: message,
        status: status
      };
    }
    this.dialogRef.close(formdata);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  getCurrentUser() {
    if (this.newPassword === this.confirmPassword) {
      const subscription = this.authService.getCurrentUser();
      subscription.subscribe((data: any) => {
        if (data) {
          this.username = data.username;
          this.resetPassword();
        }
      });
    } else {
      this.openSnackBar('Пароль не изменён, так как новый пароль повторен неправильно.', 'Ошибка !');
    }
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 2000,
    });
  }

  resetPassword() {
    this.http.post('/auth/reset/password', {
      username: this.username,
      oldPassword: this.oldPassword,
      newPassword: this.newPassword
    }).subscribe(res => {
      if (res) {
        this.passMessage('Вы успешно сменили пароль !', 'Успешно !');
      }
    }, (err) => {
      this.passMessage(err.error.message, 'Ошибка !');
    });
  }

}
