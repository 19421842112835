import {Injectable, OnDestroy} from '@angular/core';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {switchMap, takeUntil, tap} from 'rxjs/operators';
import {MatDialog, MatDialogRef} from '@angular/material';
import {MessagePopupComponent} from '../components/message-popup/message-popup.component';
import {TranslateService} from '@ngx-translate/core';
import {Router} from '@angular/router';
import {filter} from 'rxjs/internal/operators';

type connectionMessage = 'error' | 'resume';

@Injectable({
  providedIn: 'root'
})
export class ConnectionService implements OnDestroy {
  private readonly destroyed$: Subject<void>;
  private readonly connectionStatus$: BehaviorSubject<boolean>;
  private readonly connectionMessage$: BehaviorSubject<string>;
  private readonly connectionMessageErrorKey: string;
  private readonly connectionMessageResumeKey: string;
  private dialogRef: MatDialogRef<MessagePopupComponent> | null;

  constructor(
    private router: Router,
    private translateService: TranslateService,
    private matDialog: MatDialog
  ) {
    this.destroyed$ = new Subject<void>();
    this.connectionStatus$ = new BehaviorSubject<boolean>(true);
    this.connectionMessage$ = new BehaviorSubject<string>('');
    this.connectionMessageErrorKey = 'Authorization.Connection.Message.Error';
    this.connectionMessageResumeKey = 'Authorization.Connection.Message.Resume';
    this.dialogRef = null;
  }

  public ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  public checkConnection(): void {
    this.listenOnlineEvents();
    this.listenOfflineEvents();
  }

  public getOnlineStatus(): Observable<boolean> {
    return (
      this.getConnectionStatus()
        .pipe(
          filter((status: boolean) => status === true),
          takeUntil(this.destroyed$)
        )
    );
  }

  public getOfflineStatus(): Observable<boolean> {
    return (
      this.getConnectionStatus()
        .pipe(
          filter((status: boolean) => status === false),
          takeUntil(this.destroyed$)
        )
    );
  }

  public getConnectionStatus(): Observable<boolean> {
    return this.connectionStatus$.asObservable();
  }

  private listenOnlineEvents(): void {
    window.addEventListener('online', (event: Event) => {
      this.setConnectionStatus(true);
      this.getMessageTranslation('resume')
        .pipe(
          takeUntil(this.destroyed$)
        )
        .subscribe();
    });
  }

  private listenOfflineEvents(): void {
    window.addEventListener('offline', (event: Event) => {
      this.setConnectionStatus(false);
      if (!this.dialogRef) {
        this.messageDialogAfterDialog()
          .pipe(
            takeUntil(this.destroyed$)
          )
          .subscribe(
            (response: any) => {
              this.dialogRef = null;
            }
          );
      }
    });
  }

  private setConnectionStatus(status: boolean): void {
    this.connectionStatus$.next(status);
  }


  private getConnectionMessage(): Observable<string> {
    return this.connectionMessage$.asObservable();
  }

  private setConnectionMessage(message: string): void {
    this.connectionMessage$.next(message);
  }

  private messageDialogAfterDialog(): Observable<any> {
    return (
      this.getMessageTranslation('error')
        .pipe(
          switchMap((text: string) => {
            this.dialogRef = this.getMessageDialogRef();
            return this.dialogRef.afterClosed();
          }),
          takeUntil(this.destroyed$)
        )
    );
  }

  private getMessageTranslation(type: connectionMessage): Observable<string> {
    let key: string;
    switch (type) {
      case 'error':
        key = this.connectionMessageErrorKey;
        break;
      case 'resume':
        key = this.connectionMessageResumeKey;
        break;
    }
    return (
      this.translateService
        .get(key)
        .pipe(
          tap((text: string) => {
            this.setConnectionMessage(text);
          }),
          takeUntil(this.destroyed$)
        )
    );
  }

  private getMessageDialogRef(): MatDialogRef<MessagePopupComponent> {
    return this.matDialog.open(MessagePopupComponent, {
      width: '80vw',
      height: '60vh',
      maxWidth: '500px',
      maxHeight: '200px',
      disableClose: true,
      data: {
        message: this.getConnectionMessage(),
        closeButtonToggle: this.getConnectionStatus()
      }
    });
  }
}
