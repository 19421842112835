import {Directive, HostListener, ElementRef, Input} from '@angular/core';
import {NgControl} from '@angular/forms';

@Directive({
  selector: '[appUppercaseUnderscore]'
})
export class UppercaseUnderscoreDirective {
  @Input('appUppercaseUnderscore') title = '';

  constructor(private el: ElementRef, private control: NgControl) {
  }

  @HostListener('input', ['$event'])
  onInputChange(event: Event) {
    if (this.title === 'AddNewCategoryFile') {
      const input = event.target as HTMLInputElement;
      const transformedValue = input.value.toUpperCase().replace(/ /g, '_');
      this.control.control.setValue(transformedValue, {emitEvent: false});
    }
  }
}
