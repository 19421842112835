import {Injectable} from '@angular/core';
import {Nullable, NullableUndefined} from '../shared/models/types.model';
import {TOKEN_STORAGE_KEY} from '../shared/models/auth.model';

@Injectable({
  providedIn: 'root'
})
export class AuthTokenService {
  public get tokenFromStorage(): Nullable<string> {
    return window.localStorage.getItem(TOKEN_STORAGE_KEY);
  }

  public setToken(token: NullableUndefined<string>): void {
    if (token) {
      window.localStorage.setItem(TOKEN_STORAGE_KEY, token);
    }
  }

  public removeToken(): void {
    if (this.tokenFromStorage) {
      window.localStorage.removeItem(TOKEN_STORAGE_KEY);
    }
  }
}
