import {Injectable, OnDestroy} from '@angular/core';
import {JwtHelperService} from '@auth0/angular-jwt';
import {ApiService} from './api.service';
import {ASYNC_STORAGE_LOGOUT_KEY,
  AsyncStorageLogoutData,
  auth,
  AuthLogoutParams,
  isAsyncStorageLogoutData,
  TOKEN_STORAGE_KEY} from '../shared/models/auth.model';
import {BehaviorSubject, EMPTY, Observable, of} from 'rxjs';
import {AdminService} from './admin.service';
import {MatDialog} from '@angular/material';
import {Params, Router} from '@angular/router';
import {TokenService} from './token.service';
import {Nullable, NullableUndefined} from '../shared/models/types.model';
import {getQueryParams} from '../shared/utils/web.api.utility';
import {catchError, filter, first, switchMap, tap} from 'rxjs/operators';
import {SedApiUser} from 'kaz-gis/lib/shared/models/auth.model';
import {NULL$, TRUE$} from '../shared/rxjs/observables';
import {HttpClient} from '@angular/common/http';
import {ConnectionService} from './connection.service';
import {dateExceedsAnother} from '../shared/utils/time-date.utils';

@Injectable({
  providedIn: 'root'
})
export class AuthService implements OnDestroy {
  private readonly jwtHelper: JwtHelperService;
  private readonly userInfo$: BehaviorSubject<auth.User | null>;
  private readonly currentUser$: BehaviorSubject<auth.User | null>;
  private readonly authNotifier$: BehaviorSubject<boolean | null>;
  private readonly sedApiUserData$: BehaviorSubject<Nullable<SedApiUser>>;
  private tokenExpiration: number;
  private asyncLogout: boolean;
  private serverLogout: boolean;
  private currentUser: auth.User | null;
  private onlineStatus?: boolean;

  constructor(
    private tokenService: TokenService,
    private api: ApiService,
    private adminService: AdminService,
    public dialog: MatDialog,
    private router: Router,
    private httpClient: HttpClient,
    private connectionService: ConnectionService
  ) {
    this.jwtHelper = new JwtHelperService();
    this.userInfo$ = new BehaviorSubject<auth.User | null>(null);
    this.currentUser$ = new BehaviorSubject<auth.User | null>(null);
    this.authNotifier$ = new BehaviorSubject<boolean | null>(null);
    this.sedApiUserData$ = new BehaviorSubject<Nullable<SedApiUser>>(null);
    this.tokenExpiration = NaN;
    this.asyncLogout = false;
    this.serverLogout = true;
    this.currentUser = null;

    this.checkOnlineStatus();
    this.listenStorageEvents();
    this.init();
  }

  public get tokenExpirationTime(): number {
    return this.tokenExpiration;
  }

  public set tokenExpirationTime(time: number) {
    this.tokenExpiration = Number.isFinite(time) ? time : NaN;
  }

  public get serverLogoutFlag(): boolean {
    return this.serverLogout;
  }

  public set serverLogoutFlag(flag: boolean) {
    this.serverLogout = flag;
  }

  public get tokenStorageKey(): string {
    return TOKEN_STORAGE_KEY;
  }

  public get tokenFromStorage(): string {
    return this.tokenService.tokenFromStorage;
  }

  private static getAsyncStorageLogoutData(): AsyncStorageLogoutData | null {
    const stringData: string | null = localStorage.getItem(ASYNC_STORAGE_LOGOUT_KEY);
    if (stringData) {
      let storageData: any = null;
      try {
        storageData = JSON.parse(stringData);
        if (isAsyncStorageLogoutData(storageData)) {
          const token: string | undefined = storageData.token;
          const expires: number | undefined = storageData.expires;
          if (token && expires) {
            return {
              token,
              expires
            };
          }
        }
      } catch {
      }
    }
    return null;
  }

  private static setAsyncStorageLogoutData(
    token: string,
    expires: number
  ): void {
    if (token && expires) {
      localStorage.setItem(ASYNC_STORAGE_LOGOUT_KEY, JSON.stringify({
        token,
        expires
      }));
    }
  }

  private static logoutFromPortal(token: string): void {
    if (token) {
      navigator.sendBeacon(`/auth/logout?token=${token}`);
    }
  }

  private static removeAsyncStorageLogoutToken(): void {
    localStorage.removeItem(ASYNC_STORAGE_LOGOUT_KEY);
  }

  public ngOnDestroy(): void {
    this.userInfo$.complete();
    this.currentUser$.complete();
    this.authNotifier$.complete();
  }

  public getCurrentUser(): Observable<any> {
    return this.currentUser$.asObservable();
  }

  public isAuthenticated(): boolean {
    return this.isValidToken(this.tokenFromStorage);
  }

  public getTokenData(): any {
    const token: string = this.tokenFromStorage;
    let tokenData: any = null;
    try {
      tokenData = this.jwtHelper.decodeToken(token);
    } catch {
    }
    return tokenData;
  }

  public hasRole(...roles: string[]): boolean {
    const tokenData: any = this.getTokenData();
    if (tokenData && Array.isArray(tokenData.authorities)) {
      return tokenData.authorities.some((role: string) => roles.includes(role));
    }
    return false;
  }

  public hasUserRoleGroup(role: string) {
    const tokenData: any = this.getTokenData();
    if (tokenData.authorities) {
      return tokenData.authorities.some(e => e.startsWith(role));
    }
  }

  public register(data) {
    return this.api.post_auth('users/register', data, null, false);
  }

  public activateUser(token) {
    return this.api.post_auth(`users/activate?activateToken=${token}`, null, null, false);
  }

  public reactivateUser(email) {
    return this.api.post_auth(`users/reactivate?email=${email}`, null, null, false);
  }

  public logout(
    {
      redirect = true,
      serverLogout = true,
      asyncLogout = false,
      expires = NaN
    }: AuthLogoutParams = {}
  ): void {
    if (serverLogout && !asyncLogout) {
      AuthService.logoutFromPortal(this.tokenFromStorage);
    }
    if (asyncLogout) {
      AuthService.setAsyncStorageLogoutData(this.tokenFromStorage, expires);
    }
    this.resetAuthorization(true, redirect);
  }

  getUsersByRole(roleId: number, subserviceId: number) {
    return this.api.get2(`users/roles?roleId=${roleId}&subserviceId=${subserviceId}`);
  }

  resetPassWithForgotByToken(token: string, passcode: string) {
    const body = {pwd: passcode};
    return this.httpClient.post<any>(`/auth/password/reset?token=${token}`, body);
  }

  public getUserInfo(): Observable<auth.User | null> {
    return this.userInfo$.asObservable();
  }

  public setUserInfo(data: auth.User | null): void {
    this.currentUser = data;
    this.userInfo$.next(data);
  }

  public setAuthorization(token: string): Observable<boolean> {
    if (this.isValidToken(token)) {
      this.tokenService.setToken(token);
      return this.setUserData();
    } else {
      if (this.validJWT(token)) {
        AuthService.logoutFromPortal(token);
      }
      return of(false);
    }
  }

  public getAuthNotifications(): Observable<boolean> {
    return this.authNotifier$.asObservable().pipe(
      filter((flag: boolean | null) => typeof flag === 'boolean')
    );
  }

  public setAsyncStorageLogoutObservable(
    serverLogout: boolean,
    expires: number,
    unload: boolean
  ): void {
    this.updateAsyncLogoutFlag(true);
    if (!unload) {
      this.saveAsyncStorageLogoutToken(serverLogout, expires)
        .pipe(
          switchMap((flag: boolean) => {
            if (flag) {
              return this.connectionService.getOnlineStatus();
            }
            return EMPTY;
          }),
          first()
        )
        .subscribe(
          () => {
            this.asyncStorageLogout();
            this.updateAsyncLogoutFlag(false);
          }
        );
    }
  }

  private setCurrentUser(user: any): void {
    this.currentUser$.next(user);
  }

  private init(): void {
    this.initialAsyncLogout();

    const tokenPresent: boolean = this.tokenIsPresentInHref();
    if (tokenPresent) {
      const params: Params | null = getQueryParams(window.location.href);
      const token = params[this.tokenStorageKey];
      if (this.isValidToken(token)) {
        this.setAuthorization(token).subscribe();
      }
    } else if (this.isValidToken(this.tokenFromStorage)) {
      this.setUserData()
        .subscribe();
    } else {
      this.resetAuthorization(false, !tokenPresent);
    }
  }

  private tokenIsPresentInHref(): boolean {
    const params: Params | null = getQueryParams(window.location.href);
    return params ? !!params[this.tokenStorageKey] : false;
  }

  private getUserByEmail(email: string): void {
    this.adminService.getUserByEmail(email)
      .pipe(
        catchError(() => of(null))
      )
      .subscribe((data: any) => {
        if (data) {
          this.setCurrentUser(data);
        }
      });
  }

  private listenStorageEvents(): void {
    window.addEventListener('storage', (event: StorageEvent) => {
      if (event.key === this.tokenStorageKey) {
        if (event.newValue) {
          this.setAuthorization(event.newValue)
            .subscribe();
        } else {
          this.resetAuthorization();
        }
      }
    });
  }

  private clearToken(): void {
    this.tokenService.removeToken();
    this.sendAuthNotification(false);
  }

  private resetAuthorization(
    clearSenders: boolean = true,
    redirect: boolean = true
  ): void {
    this.clearToken();
    if (clearSenders) {
      this.clearUserInfo();
      this.clearCurrentUser();
    }
    if (redirect) {
      this.router.navigate(['/']).then();
    }
  }

  private setUserData(): Observable<boolean> {
    return (
      this.getCurrentUserData()
        .pipe(
          switchMap((data: any) => {
            const flag: boolean = !!data;
            this.setUserInfo(data);
            this.sendAuthNotification(flag);
            this.getCurrentUserByEmail();
            if (!flag) {
              this.logout();
            }
            if (flag) {
              return (
                this.getSedApiUserDataByName(data.username)
                  .pipe(
                    switchMap(() => TRUE$)
                  )
              );
            }
            return of(flag);
          })
        )
    );
  }

  private getCurrentUserByEmail(): void {
    if (
      this.currentUser
      && this.currentUser.username
      && !this.currentUser.username.includes('IIN')
      && !this.currentUser.username.includes('BIN')
    ) {
      this.getUserByEmail(this.currentUser.username);
    }
  }

  private getCurrentUserData(): Observable<any> {
    const tokenData: any = this.getTokenData();
    if (tokenData && tokenData.userId) {
      return (
        this.api
          .get(`users/${tokenData.userId}`)
          .pipe(
            catchError(() => of(null))
          )
      );
    }
    return of(null);
  }

  private sendAuthNotification(flag: boolean): void {
    this.authNotifier$.next(flag);
  }

  private isValidToken(token?: string): boolean {
    if (!token) {
      return false;
    }
    let valid: boolean;
    try {
      valid = this.validJWT(token) && !this.jwtHelper.isTokenExpired(token);
    } catch {
      valid = false;
    }
    return valid;
  }

  private validJWT(token: string): boolean {
    try {
      return !!this.jwtHelper.decodeToken(token);
    } catch {
      return false;
    }
  }

  private clearUserInfo(): void {
    this.setUserInfo(null);
  }

  private clearCurrentUser(): void {
    this.setCurrentUser(null);
  }

  private updateAsyncLogoutFlag(flag: boolean): void {
    this.asyncLogout = flag;
  }

  private checkOnlineStatus(): void {
    this.connectionService
      .getConnectionStatus()
      .subscribe(
        (status: boolean) => {
          this.onlineStatus = status;
        }
      );
  }

  private asyncStorageLogout(): void {
    const storageData: AsyncStorageLogoutData | null = AuthService.getAsyncStorageLogoutData();
    if (isAsyncStorageLogoutData(storageData)) {
      AuthService.removeAsyncStorageLogoutToken();
      if (dateExceedsAnother(Date.now(), storageData.expires) !== false) {
        AuthService.logoutFromPortal(storageData.token);
      } else {
        this.setAuthorization(storageData.token)
          .subscribe();
      }
    }
  }

  private initialAsyncLogout(): void {
    if (!this.tokenFromStorage) {
      if (this.onlineStatus) {
        this.asyncStorageLogout();
      } else {
        this.connectionService
          .getOnlineStatus()
          .pipe(
            first()
          )
          .subscribe(
            () => {
              this.asyncStorageLogout();
            }
          );
      }
    }
  }

  private saveAsyncStorageLogoutToken(
    serverLogout: boolean,
    expires: number
  ): Observable<boolean> {
    this.logout({
      serverLogout,
      asyncLogout: true,
      expires
    });
    const tokenData: AsyncStorageLogoutData | null = AuthService.getAsyncStorageLogoutData();
    return of(!!tokenData);
  }

  private sendSedApiUserData(user?: Nullable<SedApiUser>): void {
    this.sedApiUserData$.next(user || null);
  }

  public getSedApiUserData(): Observable<any> {
    return this.sedApiUserData$.asObservable();
  }

  private getSedApiUserDataByName(
    name: NullableUndefined<string>
  ): Observable<Nullable<SedApiUser>> {
    if (name) {
      return (
        this.adminService
          .getUserByEmail(name)
          .pipe(
            tap((data: Nullable<SedApiUser>) =>
              this.sendSedApiUserData(data)),
            catchError(() => {
              this.sendSedApiUserData();
              return NULL$;
            })
          )
      );
    } else {
      this.sendSedApiUserData();
      return NULL$;
    }
  }
}
