// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  localEnvironment: {
    flag: true
  },
  testEnvironment: {
    flag: false,
    access: {
      login: null,
      passcode: null
    }
  },
  project: {
    urls: {
      default: {
        es: 'http://eatyrau.kz',
        bp: 'http://bp.eatyrau.kz'
      },
      geo: {
        gis: {
          wms: {
            short: '/geoserver/atyrau/wms',
            full: 'http://geo.eatyrau.kazgisa.kz/geoserver/atyrau/wms'
          }
        },
        utilities: {
          wms: {
            short: '/geoserver/utilities/wms'
          }
        }
      },
      dm: {
        utilities: {
          wms: {
            full: 'http://dm.eatyrau.kazgisa.kz/geoserver/'
          }
        }
      }
    }
  },
  map: {
    center: {
      lat: 47.101,
      lng: 51.905
    }
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
