import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {ApiService} from '../services/api.service';
import {app} from '../shared/models/application.model';
import {dic} from '../shared/models/dictionary.model';
import * as FileSaver from 'file-saver';
import {RedirectService} from './redirect.service';
import {Nullable} from '../shared/models/types.model';
import TemplateFileCategory = dic.TemplateFileCategory;
import {forkJoin, from, Observable, of, throwError} from 'rxjs';
import {catchError, concatMap, filter, tap} from 'rxjs/operators';
import {NULL$} from '../shared/rxjs/observables';

@Injectable()
export class FileService {

  constructor(
    private http: HttpClient,
    private api: ApiService,
    private redirectService: RedirectService,
  ) {
  }

  public getAddFileReq(filesToUpload: File[] = [], categories: string = '[]') {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');
    const formData = new FormData();
    for (const item of filesToUpload) {
      formData.append('files[]', item);
    }
    formData.append('categories', categories);
    return this.http.post('/eqyzmet/storage/files', formData, {headers}).pipe(
      tap(() => alert('Файл успешно загружен.')),
      catchError(err => {
        alert('Невозможно сохранить файл. Попробуйте позже.');
        return throwError(err);
      })
    );
  }


  public getAddFileSingleReq(fileToUpload: File, category: string = null, notificationOff = false) {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');
    const formData = new FormData();
    formData.append('file', fileToUpload);
    formData.append('fileName', fileToUpload.name);
    formData.append('fileCategory', category);
    return this.http.post('/fileserver/api/files/upload', formData, {headers}).pipe(
      tap(() => {
        if (!notificationOff) {
          // If the request was successful, notify the user.
          alert('Файл успешно загружен.');
        }
      }),
      catchError((error) => {
        // If there was an error, notify the user.
        alert('Невозможно сохранить файл. Попробуйте позже.');
        if (!notificationOff) {
          // If there was an error, notify the user.
          alert('Невозможно сохранить файл. Попробуйте позже.');
        }
        return throwError(error);
      })
    );
  }

  private saveAppFile(appId, field, container, fileObject): Observable<any> {
    return new Observable<any>(observer => {
      const files = [];
      fileObject.objectId = fileObject.uid;
      fileObject.fileCategory = field.category;
      fileObject.name = fileObject.fileName;
      fileObject.size = fileObject.fileSize;
      files.push(fileObject);

      setTimeout(() => {
        this.api.post2(`userapp/${appId}/files`, files).subscribe({
          next: (data: any) => {
            if (data && data.body && data.body.length > 0) {
              container.id = data.body[0].id;
            }
            container.loading = false;

            observer.next(data);
            observer.complete();
          }
        });
      }, 1000);
    });
  }

  uploadAllFiles(appId, fields): Observable<any> {
    return new Observable<any>(observer => {
      if (fields.length === 0) {
        observer.next();
        observer.complete();
      }

      const fieldStreams = fields.map(field =>
        from(field.fileContainers).pipe(
          concatMap((container: any) => {
            if (container.objectId != null) {
              return of(null);
            }

            return this.getAddFileSingleReq(container.file, null, true).pipe(
              tap((response: any) => {
                container.objectId = response.uid;
              }),
              concatMap(fileObject => this.saveAppFile(appId, field, container, fileObject))
            );
          }),
          filter(result => result !== null)
        )
      );

      forkJoin(fieldStreams).subscribe({
        next: () => {
          observer.next();
          observer.complete();
        },
        error: err => observer.error(err)
      });
    });
  }

  public getAppFileCategories(subserviceId) {
    return this.api.get('dict/file-types?subserviceId=' + subserviceId);
  }

  getUploadedFiles(appId: number): Observable<any[]> {
    return this.http.get<any[]>(`sedapi/userapp/${appId}/files`);
  }

  public downloadFileAsBytes(id: string): Observable<Blob | null | never> {
    if (id) {
      return (
        this.downloadFileAsBytes(`files/download/${id}`)
      );
    }
    return NULL$;
  }

  // TODO pdf uploadks osini  koldanu
  public uploadCommunalPdf(fileToUpload: File, category: string, appId: number, orgId: number) {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');
    const formData = new FormData();
    formData.append('pdf', fileToUpload);
    return this.http.post(`/eqyzmet/api/app/${appId}/communal/${orgId}/approved/new`, formData, {headers});
  }

  public getDeleteFileReq(type, id, fileId) {
    const url = `${type}/${id}/files/${fileId}`;
    return this.api.delete(url);
  }

  deleteFileByUrl(url) {
    return this.api.delete(url);
  }

  public getDeleteAddressFileReqByDeptEmployee(fileId: number) {
    const url = `app/files/${fileId}/address`;
    return this.api.delete(url);
  }

  public downloadFileReq(id: string) {
    this.redirectService.handleLink(
      `/fileserver/api/files/download/${id}`,
      '_blank'
    );
  }

  public save(fileObjects, fileObject, category, url, index, callback?: any) {
    const files = [];
    fileObject.objectId = fileObject.uid;
    fileObject.fileCategory = category;
    fileObject.name = fileObject.fileName;
    fileObject.size = fileObject.fileSize;
    files.push(fileObject);
    fileObjects[index].objectId = fileObject.uid;
    setTimeout(() => {
      this.api.post2(url, files).subscribe((data: any) => {
        if (data && data.body && data.body.length > 0) {
          fileObjects[index].id = data.body[0].id;
          if (callback) {
            callback();
          }
        }
        fileObjects[index].loading = false;
      });
    }, 1000);
  }

  public saveFiles(fileObjects: any[], category: string, url, callback?: any) {
    if (fileObjects) {
      for (let i = 0; i < fileObjects.length; i++) {
        if (fileObjects[i].loading === true) {
          this.getAddFileSingleReq(fileObjects[i].file, category).subscribe((fileObject: any) => {
            this.save(fileObjects, fileObject, category, url, i, callback);
          }, (error) => {
            console.log(error);
            fileObjects[i].loading = false;
          });
        }
      }
    }
  }

  public checkingFileCategoriesForRequired(app_: app.App, fileCategories: dic.CategoryFiles[]) {
    if (app_.files) {
      return fileCategories.every((e: dic.CategoryFiles) => {
        return e.required ? e.categoryFiles.length > 0 || e.categoryFilesUpload.length > 0 : true;
      });
    }
    return fileCategories.every((e: dic.CategoryFiles) => {
      return e.required ? e.categoryFilesUpload.length > 0 : true;
    });
  }

  generatePdfFile(id) {
    return this.api.getArrayBuffer(`userapp/${id}/generate`);
  }

  generatePdfFromContent(content) {
    this.api.postArrayBuffer(`templates/preview`, content).subscribe(res => {
      this.downloadGeneratedFile(res, 'preview-content.pdf');
    });
  }

  public getFileCategories(): Observable<Nullable<TemplateFileCategory[]> | never> {
    return this.api.get2('file/categories');
  }

  downloadGeneratedFile(file, fileName) {
    FileSaver.saveAs(file, fileName);
  }

  generateExcelFileWithApp(body = null, url, name, page: number, size: number) {
    this.api.postArrayBuffer(`report/${url}?page=${page}&size=${size}`, body).subscribe(res => {
      this.downloadGeneratedFile(res, `${name}_${new Date().getTime()}`);
    });
  }

  public generateExcelFileWithDifferentData(url, name, type = null) {
    this.api.getArrayBuffer(`report/${url}?type=${type}`).subscribe(res => {
      this.downloadGeneratedFile(res, `${name}_${new Date().getTime()}`);
    });
  }

  generateApprovalSheetFile(appId) {
    return this.api.getArrayBuffer(`userapp/${appId}/cn/agreement/preview`).subscribe(res => {
      this.downloadGeneratedFile(res, `approval_sheet_${new Date().getTime()}`);
    });
  }

  downloadApprovalSheetFile(appId, callback?) {
    return this.api.getArrayBuffer(`userapp/${appId}/cn/agreement`).subscribe(res => {
      this.downloadGeneratedFile(res, `approval_sheet_${new Date().getTime()}`);
      callback(res);
    });
  }

  downloadApprovalSheetDocxFile(appId) {
    return this.api.getArrayBuffer(`userapp/${appId}/cn/agreement/docx`).subscribe(res => {
      this.downloadGeneratedFile(res, `approval_sheet_${new Date().getTime()}`);
    });
  }

  public deleteGeneratedFile(id) {
    const url = `userapp/${id}/generate`;
    return this.api.delete(url);
  }

  public correctFileSize(bytes) {
    if (bytes < 1024) {
      return bytes + ' Bytes';
    }
    if (bytes < 1048576) {
      return (bytes / 1024).toFixed(0) + ' KB';
    }
    if (bytes < 1073741824) {
      return (bytes / 1048576).toFixed(3) + ' MB';
    } else {
      return (bytes / 1073741824).toFixed(3) + ' GB';
    }
  }

  public correctFileName(str) {
    const correctName = str.length > 12 ? str.substring(0, 12) : str;
    return correctName;
  }

  public uploadSchema(fileToUpload: File, validateValue : boolean, parms?: any) {
    const formData = new FormData();
    formData.append('file', fileToUpload, fileToUpload.name);
    formData.append("reportProgress", "true");
    const token = localStorage.getItem('access_token');
    const restPath = `sedapi/bpmn/scheme/newprocdef?validation=${validateValue}`;

    return this.http.post<any>(restPath, formData, {
      reportProgress: true,
      headers: {
        Authorization: 'Bearer ' + token
      },
      observe: 'events',
      params: parms
    }).pipe(
      catchError((error) => {
        // If there was an error, notify the user.
        alert('Невозможно сохранить файл. Попробуйте позже.');
        return throwError(error);
      })
    );
  }

  public downloadSortedList(flow: number, specProgram: string, sortParam: string): Observable<Blob> {
    const url_path = `report/export-spec-program?flow=${flow}&specProgram=${specProgram}&sort=${sortParam}`;
    return this.api.post5(url_path);
  }
}
