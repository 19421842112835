import {Pipe, PipeTransform} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Pipe({
    name: 'dynamicTranslate',
    pure: false
})
export class DynamicTranslatePipe implements PipeTransform {
    private readonly defaultLang;

    constructor(public translate: TranslateService) {
        this.defaultLang = this.translate.getDefaultLang();
    }

    transform(obj: any, name?: string): string {
        if (!obj) {
            return '';
        }

        if (name) {
            return obj[name + this.getCurrentLang(true)];
        }

        return obj[this.getCurrentLang(false)];
    }

    private getCurrentLang(capitalize: boolean): string {
        const lang = this.translate.currentLang || this.defaultLang;
        if (capitalize) {
            return lang === 'kk' ? 'Kz' : 'Ru';
        } else {
            return lang === 'kk' ? 'kz' : 'ru';
        }
    }
}
