export type DateType = number | string | Date;

export function isValidDate(date: Date): boolean {
  return date ? Number.isFinite(date.getTime()) : false;
}

export function getUTCTimestamp(dateType?: DateType): number {
  const date: Date = new Date(dateType);
  if (isValidDate(date)) {
    return Date.UTC(
      date.getUTCFullYear(),
      date.getUTCMonth(),
      date.getUTCDate(),
      date.getUTCHours(),
      date.getUTCMinutes(),
      date.getUTCSeconds(),
      date.getUTCMilliseconds()
    );
  }
  return NaN;
}

export function dateExceedsAnother(dateType1: DateType, dateType2: DateType): boolean | null {
  const date1: Date = new Date(dateType1);
  const date2: Date = new Date(dateType2);
  if (isValidDate(date1) && isValidDate(date2)) {
    const timestamp1: number = getUTCTimestamp(date1);
    const timestamp2: number = getUTCTimestamp(date2);
    return timestamp1 > timestamp2;
  }
  return null;
}
