import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {dic} from '../shared/models/dictionary.model';
import {Observable, Subject} from 'rxjs';
import {FileService} from './file.service';
import {app} from '../shared/models/application.model';
import App = app.App;
import {EXECUTORS} from '../shared/utils/constants';
import UserRoles = dic.UserRoles;
import Decide = dic.Decide;
import {AuthService} from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class ProblemService {
  private menuSubject = new Subject<any>();
  private currentTask = new dic.TaskData();
  private taskDecideSubject = new Subject();
  private hasHead = new Subject();
  private taskSubject = new Subject();
  public currentPage: number;
  private application: any;
  orderTextReserve: string;


  constructor(private api: ApiService,
              private fileSvc: FileService,
              private authService: AuthService) {
  }

  setTaskAsOpened(taskId: string | number): Observable<any> {
    return this.api.put2(`tasks/${taskId}/open`);
  }

  sendTaskSubject(task: any) {
    this.taskSubject.next(task);
  }

  getTaskSubject(): Observable<any> {
    return this.taskSubject.asObservable();
  }

  sendTaskDecideSubject(decide: any) {
    return this.taskDecideSubject.next(decide);
  }

  getTaskDecideSubject(): Observable<any> {
    return this.taskDecideSubject.asObservable();
  }

  sendHeadSubject(head: any) {
    this.hasHead.next(head);
  }

  getHeadSubject(): Observable<any> {
    return this.hasHead.asObservable();
  }

  getSidenavMenuCounts() {
    this.api.get2('analytics/menu').subscribe(res => {
      this.sendMenu(res);
    }, error => {
      this.sendMenu(null);
    });
  }

  sendMenu(menu: any): void {
    this.menuSubject.next({data: menu});
  }

  clearMenu(): void {
    this.menuSubject.next();
  }

  getMenu(): Observable<any> {
    return this.menuSubject.asObservable();
  }

  public setTask(task: any) {
    this.currentTask = task;
  }

  public getTask() {
    return this.currentTask;
  }

  public checkHasService(service: string, taskName) {
    if (taskName) {
      if (taskName.indexOf(service) !== -1) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }


  public getMyExecutedTasks(page: number, size: number, body) {
    return this.api.post2(`history/tasks/filter?page=${page}&size=${size}`, body);
  }

  public getMySentTasks(email: string, page: number, size: number) {
    return this.api.get2(`userapp?signed=true&page=${page}&size=${size}`);
  }

  public getTaskById(id: any) {
    return this.api.get2(`tasks/${id}`);
  }

  public decide(taskId, data: Decide, paramData: string = '') {
    const params = paramData ? `?${paramData}` : '';
    return this.api.post2(`tasks/${taskId}/complete` + params, data);
  }

  public getTasks(page: number, size: number, body, sort: string = null) {
    return this.api.post2(`tasks/filter?page=${page}&size=${size}&sort=${sort}`, body);
  }

  public getOperationTasks(page: number, size: number, body, sort: string = null) {
    return this.api.post2(`history/tasks/filter?page=${page}&size=${size}&sort=${sort}`, body);
  }

  public getMyTasks(page: number, size: number, body, sort: string = null) {
    return this.api.post2(`tasks/filter?page=${page}&size=${size}&sort=${sort}`, body);
  }

  public refreshTask(id: number, data: any) {
    return this.api.put2(`executions/${id}`, data);
  }

  public getHistory(id, subserviceId) {
    return this.api.get2(`history/userapp/${id}?subserviceId=${subserviceId}`);
  }

  public getHistoryById(id) {
    return this.api.get2(`history/tasks/${id}`);
  }

  public getTaskVariablesById(id) {
    return this.api.get2(`history/tasks/${id}/variables`);
  }

  public getUzpFile(appId) {
    return this.api.postArrayBuffer(`userapp/${appId}/preview/uzp`);
  }

  public generatePdf(id) {
    return this.api.getArrayBuffer(`userapp/${id}/preview`).subscribe(res => {
      this.fileSvc.downloadGeneratedFile(res, 'preview.pdf');
    });
  }

  public matchingData(content, dataTaskDeside) {
    const desideColumns = Object.getOwnPropertyNames(dataTaskDeside);
    desideColumns.forEach(column => {
      if (column === 'internalFiles' || column === 'files') {
        dataTaskDeside[column] = content[column] ? content[column] : [];
      } else {
        if (content[column]) {
          dataTaskDeside[column] = content[column];
        }
      }
    });
    if ([83, 84, 85, 86, 87, 88, 89, 90].includes(dataTaskDeside.communalOrganizationId)) {
      dataTaskDeside.approved = content.approved;
    }
    return dataTaskDeside;
  }

  public extractContent(s) {
    let span = document.createElement('span');
    span.innerHTML = s;
    return span.textContent || span.innerText;
  }

  public getTemplatesBySubServiceId(id) {
    return this.api.get2(`templates?subserviceId=${id}`);
  }

  public hasText(str, value) {
    const result = str.indexOf(value) === -1 ? false : true;
    return result;
  }

  public onReady(editor) {
    editor.ui.getEditableElement().parentElement.insertBefore(
      editor.ui.view.toolbar.element,
      editor.ui.getEditableElement()
    );
  }

  public removeTask(processInstanceId, appId) {
    return this.api.delete(`executions/${processInstanceId}?appId=${appId}`);
  }

  restartTask(processInstanceId, appId) {
    return this.api.post2(`executions/${processInstanceId}/restart?appId=${appId}`);
  }


  replaceTemplateTexts(text, app: app.App) {
    const re = /(<figure>(?:[^<](?!\/figure))*<\/figure>)|(fio)/gi;

    this.application = app;

    /*if (this.hasText(text, 'fullName')) {
      var self = this;
      text = text.replace(re, function fnCallback(s) {
        if (s === 'fullName') {
          return `${self.application.firstName} ${self.application.secondName} ${self.application.lastName}`;
        }
        return s;
      });
    }*/
    if (this.hasText(text, 'fullName')) {
      const fullName = `${app.firstName} ${app.secondName} ${app.lastName}`;
      text = text.replace(/fullName/gi, fullName);
    }
    if (this.hasText(text, 'regNum')) {
      text = text.replace(/regNum/gi, app.id);
    }
    if (this.hasText(text, 'nameOfLegalEntity')) {
      text = text.replace(/nameOfLegalEntity/gi, app.orgName);
    }
    if (this.hasText(text, 'appNameOrOrgName')) {
      text = text.replace(/appNameOrOrgName/gi, this.getAppNameOrOrgName(app));
    }
    if (this.hasText(text, 'regDate')) {
      text = text.replace(/regDate/gi, new Date(app.createDate).toLocaleDateString('ru-Ru'));
    }
    if (this.hasText(text, 'objAddress')) {
      text = text.replace(/objAddress/gi, app.objectInfo.address);
    }
    if (this.hasText(text, 'address')) {
      text = text.replace(/address/gi, app.address);
    }
    if (this.hasText(text, 'objName')) {
      text = text.replace(/objName/gi, app.objectInfo.name);
    }
    if (this.hasText(text, 'objArea')) {
      text = text.replace(/objArea/gi, app.objectInfo.area);
    }
    if (this.hasText(text, 'IIN')) {
      text = text.replace(/IIN/gi, app.iin);
    }
    if (this.hasText(text, 'BIN')) {
      text = text.replace(/BIN/gi, app.bin);
    }
    if (this.hasText(text, 'rightToUseZU')) {
      text = text.replace(/rightToUseZU/gi, app.objectInfo.useRight);
    }
    if (this.hasText(text, 'areaGA')) {
      text = text.replace(/areaGA/gi, app.objectInfo.area);
    }
    if (this.hasText(text, 'specialPurposeCurrent')) {
      text = text.replace(/specialPurposeCurrent/gi, app.objectInfo.purpose);
    }
    if (this.hasText(text, 'specialPurposeReq')) {
      text = text.replace(/specialPurposeReq/gi, app.objectInfo.purposeRequested);
    }
    if (this.hasText(text, 'reasonForChangingPurpose')) {
      text = text.replace(/reasonForChangingPurpose/gi, app.objectInfo.changeReason);
    }
    if (this.hasText(text, 'cadastreNumber')) {
      text = text.replace(/cadastreNumber/gi, app.objectInfo.cadastreNumber);
    }
    if (this.hasText(text, 'outgoingNum')) {
      text = text.replace(/outgoingNum/gi, app.appId);
    }
    if (this.hasText(text, 'outgoingDate')) {
      text = text.replace(/outgoingDate/gi, new Date(app.createDate).toLocaleDateString('ru-Ru'));
    }
    return text;
  }

  private getAppNameOrOrgName(application: app.App) {
    const result = application.bin ? application.orgName : `${application.firstName} ${application.secondName} ${application.lastName}`;
    return result;
  }

  setOrderText(orderText) {
    if (orderText) {
      this.orderTextReserve = orderText;
    }
  }

  getOrderTextDifference(orderText) {
    if (!this.orderTextReserve) {
      return orderText;
    } else {
      const re = /<[^>]*>|(&nbsp;)/gi;
      const orderTextArr = orderText.replace(re, ' ').split(' ').filter(str => str !== '');
      const orderTextReserveArr = this.orderTextReserve.replace(re, ' ').split(' ').filter(str => str !== '');
      const newWords = orderTextArr.filter(str => {
        if (!orderTextReserveArr.includes(str)) {
          return str;
        } else {
          const strIndex = orderTextReserveArr.indexOf(str);
          orderTextReserveArr.splice(strIndex, 1);
        }
      });
      if (newWords.length > 0) {
        return newWords.join('; ');
      }
    }
  }

  getCurrentPage() {
    return this.currentPage;
  }

  setCurrentPage(page) {
    this.currentPage = page;
  }

  public removeUnnecessaryFieldsFromDecide(decide: Decide) {
    delete decide.executor;
    delete decide.executorName;
    delete decide.owner;
    return decide;
  }

  public async getExecutors(task) {
    const rolesEditorIsAvailable = [30, 41, 39, 40, 42, 5];
    let users = [];
    rolesEditorIsAvailable.map(roleId => {
      this.authService.getUsersByRole(roleId, task.content.subserviceId).subscribe((res: any) => {
        if (res.length > 0) {
          users = users.concat(res);
          this.preparingUsersForEditor(users);
        }
      });
    });

  }


  preparingUsersForEditor(users) {
    const uniqueUsers = users.filter((value, index, self) => self.findIndex(t => (t.id === value.id)) === index);

    const correctUsers = uniqueUsers.map(user => {
      const newUser: any = {};
      newUser.id = `user-${user.username}`;
      newUser.name = `${user.username}`;
      return newUser;
    });

    localStorage.setItem('ozoExecutorsForEditor', JSON.stringify(correctUsers));
  }

  getUserRolesBySubserviceId(task) {
    if (task.content.subserviceId === 1 || task.content.subserviceId === 3
      || task.content.subserviceId === 9 || task.content.subserviceId === 2
      || task.content.subserviceId === 75 || task.content.subserviceId === 25) {
      return {
        head: EXECUTORS.APZ_HEAD,
        executor: EXECUTORS.APZ_EXECUTOR,
        controller: EXECUTORS.APZ_CONTROLLER
      };
    }
    if (task.content.subserviceId === 21) {
      return {
        head: EXECUTORS.PRELIMDESIGN_HEAD,
        executor: EXECUTORS.PRELIMDESIGN_EXECUTOR,
        controller: EXECUTORS.PRELIMDESIGN_CONTROLLER
      };
    }
    if (task.content.subserviceId === 17) {
      return {
        head: EXECUTORS.ADRUTOCH_HEAD,
        executor: EXECUTORS.ADRUTOCH_EXECUTOR,
        controller: EXECUTORS.ADRUTOCH_CONTROLLER
      };
    }
    if (task.content.subserviceId === 19) {
      return {
        head: EXECUTORS.ADRUPRAZ_HEAD,
        executor: EXECUTORS.ADRUPRAZ_EXECUTOR,
        controller: EXECUTORS.ADRUPRAZ_CONTROLLER
      };
    }
    if (task.content.subserviceId === 18) {
      return {
        head: EXECUTORS.ADRPRISV_HEAD,
        executor: EXECUTORS.ADRPRISV_EXECUTOR,
        controller: EXECUTORS.ADRPRISV_CONTROLLER
      };
    }
    if (task.content.subserviceId === 13) {
      return {
        head: EXECUTORS.TOPOSURVEY_HEAD,
        executor: EXECUTORS.TOPOSURVEY_EXECUTOR,
        controller: EXECUTORS.TOPOSURVEY_CONTROLLER
      };
    }
    if (task.content.subserviceId === 26) {
      return {
        head: EXECUTORS.TOPOPLAN_HEAD,
        executor: EXECUTORS.TOPOPLAN_EXECUTOR,
        controller: EXECUTORS.TOPOPLAN_CONTROLLER
      };
    }
    if (task.content.subserviceId === 31) {
      return {
        head: EXECUTORS.SUARNASY_HEAD,
        executor: EXECUTORS.SUARNASY_EXECUTOR,
        controller: null
      };
    }
    if (task.content.subserviceId === 32) {
      return {
        head: EXECUTORS.JARYK_HEAD,
        executor: EXECUTORS.JARYK_EXECUTOR,
        controller: null
      };
    }
    if (task.content.subserviceId === 33) {
      return {
        head: EXECUTORS.TEPLO_HEAD,
        executor: EXECUTORS.TEPLO_EXECUTOR,
        controller: null
      };
    }
  }

  getServicePosition(task) {
    return parseInt(task.name);
  }

  getAssignees(taskId: number, data: Decide) {
    return this.api.post2(`tasks/${taskId}/next/assignees`, data);
  }

  getAuctionCommunalStatuses(orgId: number, appId) {
    return this.api.get2(`apporg?organizationId=${orgId}&appId=${appId}`);
  }

  public getGenerateFile(body) {
    return this.api.post2(`userapp/generate-file`, body);
  }

  getFileCategories(orgId: number, appId) {
    return this.api.get2('/file/categories');
  }
}


